import React, {Component} from 'react';
import ReactDOM from 'react-dom'

class Footer extends Component {
    render() {
        let debugOutput = "";
        let footerStyles = "text-xs text-gray-500 font-sans py-1 FooterText";
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            debugOutput = <p className={footerStyles}>Ruby {this.props.ruby_version} | React {React.version} | Development</p>;
        }
        let betaChurchStatus = "";
        if (this.props.beta_church) {
            betaChurchStatus = <p className={footerStyles}>Beta church enabled</p>;
        }

        return (
            <div className="container mx-auto">
                <div className="mx-auto">
                    <p className={footerStyles}>&copy; {new Date().getFullYear()} WorshipKit LLC. Built with &hearts; in Peoria Illinois | <a className="text-blue-500 hover:text-white hover:bg-blue-500 px-1 rounded" href="mailto:support@worshipkit.com">Help</a> | <a className="text-blue-500 hover:text-white hover:bg-blue-500 px-1 rounded" href="https://worshipkit.statuspage.io">Status</a></p>
                    {debugOutput}
                    {betaChurchStatus}
                </div>
            </div>
        );
    }
};

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <Footer />,
        document.getElementById('footer'),
    )
})

export default Footer;
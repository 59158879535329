import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../global/utility';

const initialState = {
    loading_ministries: false,
    ministries: [],
    selected_ministry: null,
    sending_message: false,
    loading_channel_keys: false,
    channel_keys: null,
}


const ministriesStart = (state, action) => {
    return updateObject(state, { loading_ministries: true });
};

const ministriesSuccess = (state, action) => {
    return updateObject(state, { ministries: action.details, loading_ministries: false });
};

const ministriesFailure = (state, action) => {
    return updateObject(state, { loading_ministries: false });
};



const selectMinistryFinish = (state, action) => {
    return updateObject(state, { selected_ministry: action.ministry });
};



const ministryChannelKeysStart = (state, action) => {
    return updateObject(state, { loading_channel_keys: true });
};

const ministryChannelKeysSuccess = (state, action) => {
    return updateObject(state, { channel_keys: action.details, loading_channel_keys: false });
};

const ministryChannelKeysFailure = (state, action) => {
    return updateObject(state, { loading_channel_keys: false });
};



const sendMessageStart = (state, action) => {
    return updateObject(state, { sending_message: true });
};

const sendMessageSuccess = (state, action) => {
    return updateObject(state, { sending_message: false });
};

const sendMessageFailure = (state, action) => {
    return updateObject(state, { sending_message: false });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MINISTRIES_START: return ministriesStart(state, action);            
        case actionTypes.FETCH_MINISTRIES_SUCCESS: return ministriesSuccess(state, action);            
        case actionTypes.FETCH_MINISTRIES_FAILURE: return ministriesFailure(state, action);
        case actionTypes.SELECT_MINISTRY: return selectMinistryFinish(state, action);
        case actionTypes.FETCH_SELECTED_CHANNEL_KEYS_START: return ministryChannelKeysStart(state, action);            
        case actionTypes.FETCH_SELECTED_CHANNEL_KEYS_SUCCESS: return ministryChannelKeysSuccess(state, action);            
        case actionTypes.FETCH_SELECTED_CHANNEL_KEYS_FAILURE: return ministryChannelKeysFailure(state, action);
        case actionTypes.SEND_MESSAGE_START: return sendMessageStart(state, action);            
        case actionTypes.SEND_MESSAGE_SUCCESS: return sendMessageSuccess(state, action);            
        case actionTypes.SEND_MESSAGE_FAILURE: return sendMessageFailure(state, action);
        default: return state;
    }
};

export default reducer;

export const FETCH_MINISTRIES_START = 'FETCH_MINISTRIES_START';
export const FETCH_MINISTRIES_SUCCESS = 'FETCH_MINISTRIES_SUCCESS';
export const FETCH_MINISTRIES_FAILURE = 'FETCH_MINISTRIES_FAILURE';

export const SELECT_MINISTRY = 'SELECT_MINISTRY';

export const FETCH_SELECTED_CHANNEL_KEYS_START = 'FETCH_SELECTED_CHANNEL_KEYS_START';
export const FETCH_SELECTED_CHANNEL_KEYS_SUCCESS = 'FETCH_SELECTED_CHANNEL_KEYS_SUCCESS';
export const FETCH_SELECTED_CHANNEL_KEYS_FAILURE = 'FETCH_SELECTED_CHANNEL_KEYS_FAILURE';

export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

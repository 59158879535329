import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../global/utility';

const initialState = {
    loading_available_apps: false,
    available_apps: [],
    creating_account: false,
    failed_creating_account: false,
    loading_active_apps: false,
    active_apps: [],
    loading_inactive_apps: false,
    inactive_apps: [],
    stripe_publishable: null,
    loading_app_details: false,
    selected_app: null,
    email: null,
    loading_payment_details: false,
    payment_source: { },
    loading_ministries: false,
    ministries: [],
    apps: {},
    auth_token: null
}

const ministriesStart = (state, action) => {
    return updateObject(state, { loading_ministries: true });
};

const ministriesSuccess = (state, action) => {
    return updateObject(state, { ministries: action.details.ministries, loading_ministries: false });
};

const ministriesFailure = (state, action) => {
    return updateObject(state, { loading_ministries: false });
};

const detailsStart = (state, action) => {
    return updateObject(state, { loading_payment_details: true });
};

const detailsSuccess = (state, action) => {
    return updateObject(state, { email: action.details.email, stripe_publishable: action.details.stripe_publishable, payment_source: action.details.payment_source, loading_payment_details: false });
};

const detailsFailure = (state, action) => {
    return updateObject(state, { loading_payment_details: false });
};

const availableAppsStart = (state, action) => {
    return updateObject(state, { });
};

const availableAppsSuccess = (state, action) => {
    return updateObject(state, { available_apps: action.apps, loading_available_apps: false });
};

const availableAppsFailure = (state, action) => {
    return updateObject(state, { loading_available_apps: false });
};

const appsStart = (state, action) => {
    return updateObject(state, { });
};

const appsSuccess = (state, action) => {
    return updateObject(state, { active_apps: action.apps, loading_active_apps: false });
};

const appsFailure = (state, action) => {
    return updateObject(state, { loading_active_apps: false });
};

const inactiveAppsStart = (state, action) => {
    return updateObject(state, { });
};

const inactiveAppsSuccess = (state, action) => {
    return updateObject(state, { inactive_apps: action.apps, loading_inactive_apps: false });
};

const inactiveAppsFailure = (state, action) => {
    return updateObject(state, { loading_inactive_apps: false });
};


const appDetailsStart = (state, action) => {
    return updateObject(state, { loading_app_details: true });
};

const appDetailsSuccess = (state, action) => {
    let apps = state.apps;
    apps = updateObject(apps, {[action.details.app_module.short_name]: action.details});
    return updateObject(state, { loading_app_details: false, apps: apps });
};

const appDetailsFailure = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};


const deactivateAppStart = (state, action) => {
    return updateObject(state, { loading_app_details: true });
};

const deactivateAppSuccess = (state, action) => {
    let apps = state.apps;
    apps = updateObject(apps, {[action.details.app_module.short_name]: action.details});
    return updateObject(state, { loading_app_details: false, apps: apps });
};

const deactivateAppFailure = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};


const activateAppStart = (state, action) => {
    return updateObject(state, { loading_app_details: true });
};

const activateAppSuccess = (state, action) => {
    let apps = state.apps;
    apps = updateObject(apps, {[action.details.app_module.short_name]: action.details});
    return updateObject(state, { loading_app_details: false, apps: apps });
};

const activateAppFailure = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};



const cancelAppStart = (state, action) => {
    return updateObject(state, { loading_app_details: true });
};

const cancelAppSuccess = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};

const cancelAppFailure = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};


const subscribeAppStart = (state, action) => {
    return updateObject(state, { loading_app_details: true });
};

const subscribeAppSuccess = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};

const subscribeAppFailure = (state, action) => {
    return updateObject(state, { loading_app_details: false });
};



const createAccountStart = (state, action) => {
    return updateObject(state, { creating_account: true, failed_creating_account: false });
};

const createAccountSuccess = (state, action) => {
    return updateObject(state, { creating_account: false, failed_creating_account: false, auth_token: action.details.user.authentication_token });
};

const createAccountFailure = (state, action) => {
    return updateObject(state, { creating_account: false, failed_creating_account: true });
};



const updateToken = (state, action) => {
    return updateObject(state, { auth_token: action.details.auth_token });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MINISTRIES_START: return ministriesStart(state, action);            
        case actionTypes.FETCH_MINISTRIES_SUCCESS: return ministriesSuccess(state, action);            
        case actionTypes.FETCH_MINISTRIES_FAILURE: return ministriesFailure(state, action);
        case actionTypes.FETCH_BILLING_DETAILS_START: return detailsStart(state, action);            
        case actionTypes.FETCH_BILLING_DETAILS_SUCCESS: return detailsSuccess(state, action);            
        case actionTypes.FETCH_BILLING_DETAILS_FAILURE: return detailsFailure(state, action);
        case actionTypes.FETCH_AVAILABLE_APPS_START: return availableAppsStart(state, action);
        case actionTypes.FETCH_AVAILABLE_APPS_SUCCESS: return availableAppsSuccess(state, action);
        case actionTypes.FETCH_AVAILABLE_APPS_FAILURE: return availableAppsFailure(state, action);
        case actionTypes.FETCH_APPS_START: return appsStart(state, action);
        case actionTypes.FETCH_APPS_SUCCESS: return appsSuccess(state, action);
        case actionTypes.FETCH_APPS_FAILURE: return appsFailure(state, action);
        case actionTypes.FETCH_INACTIVE_APPS_START: return inactiveAppsStart(state, action);
        case actionTypes.FETCH_INACTIVE_APPS_SUCCESS: return inactiveAppsSuccess(state, action);
        case actionTypes.FETCH_INACTIVE_APPS_FAILURE: return inactiveAppsFailure(state, action);
        case actionTypes.FETCH_APP_DETAILS_START: return appDetailsStart(state, action);            
        case actionTypes.FETCH_APP_DETAILS_SUCCESS: return appDetailsSuccess(state, action);            
        case actionTypes.FETCH_APP_DETAILS_FAILURE: return appDetailsFailure(state, action);
        case actionTypes.DEACTIVATE_APP_START: return deactivateAppStart(state, action);
        case actionTypes.DEACTIVATE_APP_SUCCESS: return deactivateAppSuccess(state, action);
        case actionTypes.DEACTIVATE_APP_FAILURE: return deactivateAppFailure(state, action);
        case actionTypes.ACTIVATE_APP_START: return activateAppStart(state, action);
        case actionTypes.ACTIVATE_APP_SUCCESS: return activateAppSuccess(state, action);
        case actionTypes.ACTIVATE_APP_FAILURE: return activateAppFailure(state, action);
        case actionTypes.CANCEL_APP_START: return cancelAppStart(state, action);
        case actionTypes.CANCEL_APP_SUCCESS: return cancelAppSuccess(state, action);
        case actionTypes.CANCEL_APP_FAILURE: return cancelAppFailure(state, action);
        case actionTypes.SUBSCRIBE_APP_START: return subscribeAppStart(state, action);
        case actionTypes.SUBSCRIBE_APP_SUCCESS: return subscribeAppSuccess(state, action);
        case actionTypes.SUBSCRIBE_APP_FAILURE: return subscribeAppFailure(state, action);
        case actionTypes.CREATE_ACCOUNT_START: return createAccountStart(state, action);
        case actionTypes.CREATE_ACCOUNT_SUCCESS: return createAccountSuccess(state, action);
        case actionTypes.CREATE_ACCOUNT_FAILURE: return createAccountFailure(state, action);
        case actionTypes.UPDATE_TOKEN: return updateToken(state, action);
        default: return state;
    }
};

export default reducer;
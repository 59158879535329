import React, {Component, Suspense} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import * as pagerActions from '../actions/index';
import * as userActions from '../../global/user/actions/index';

const SplitView = React.lazy(() => import('../../global/split_view/split_view'));
const SectionHeader = React.lazy(() => import('../../global/table_elements/section_header/section_header'));
const TableRow = React.lazy(() => import('../../global/table_elements/table_row/table_row'));

const PagerIndex = React.lazy(() => import('../pager_index/pager_index'));
import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
const PagerSendView = React.lazy(() => import('../pager_send_view/pager_send_view'));

class PagerLayout extends Component {

    constructor(props) {
        super(props);
        this.splitView = React.createRef();
        this.authTokenCallback = this.authTokenCallback.bind(this);
        this.ministryLoadCallback = this.ministryLoadCallback.bind(this);
    }

    componentDidMount() {
        
        this.props.onUpdateAuthToken(this.authTokenCallback);
    }

    authTokenCallback(auth_token) {
        this.props.onFetchMinistries(auth_token, this.ministryLoadCallback);
    }

    sortedMinistries() {
        let ministries = []

        if (this.props.ministries) {
            ministries = this.props.ministries;
            ministries.sort(function(a, b) {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
            });
        }

        return ministries;
    }

    ministryLoadCallback() {
        let ministries = this.sortedMinistries();
        if (ministries.length > 0) {
            this.props.onSelectMinistry(ministries[0]);
            this.props.onFetchSelectedChannelKeys(this.props.auth_token, ministries[0]);
        }
    }

    goToMinistry = (ministry) => {
        this.props.onSelectMinistry(ministry);
        this.props.onFetchSelectedChannelKeys(this.props.auth_token, ministry);
        this.splitView.current.collapsePopover();
    }

    render () {

        let ministries = this.sortedMinistries();

        let ministries_section = (
            <div>
                <Suspense fallback={<LoadingIndicator />}>
                    <SectionHeader text="Ministries" />                
                    {ministries.map((ministry) => (
                        <TableRow key={ministry.id} selected={(ministry == this.props.selected_ministry)} clickEvent={() => this.goToMinistry(ministry)} text={ministry.name} icon={ministry.photo_url} />
                    ))}
                </Suspense>
            </div>
        );
        if (this.props.loading_ministries) {
            ministries_section = (
                <div>

                </div>
            );
        }

        let left = (
            <div>
                
                {ministries_section}
            </div>
        );

        let right = (
            <Suspense fallback={<LoadingIndicator />}>
                <PagerIndex />
            </Suspense>
        );

        if (this.props.selected_ministry) {
            right = (
                <Suspense fallback={<LoadingIndicator />}>
                    <PagerSendView />
                </Suspense>
            )
        }

        return (
            <Suspense fallback={<LoadingIndicator />}>
                <SplitView ref={this.splitView} left={left} right={right} />
            </Suspense>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading_ministries: state.pager.loading_ministries,
        ministries: state.pager.ministries,
        selected_ministry: state.pager.selected_ministry,
        auth_token: state.user.auth_token,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMinistries: (auth_token, callback) => dispatch(pagerActions.fetchMinistries(auth_token, callback)),
        onFetchSelectedChannelKeys: (auth_token, ministry) => dispatch(pagerActions.fetchSelectedChannelKeys(auth_token, ministry)),
        onSelectMinistry: (ministry) => dispatch(pagerActions.selectMinistry(ministry)),
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagerLayout));
import React, {Component} from 'react';
import ReactDOM from 'react-dom'

class Modal extends Component {

    static insertModalElement(element, click_outside_dismisses = true, context = null) {
        
        let modalRoot = document.getElementById('modal-root');
        ReactDOM.unmountComponentAtNode(modalRoot);
        let modalElement = (
            <Modal click_outside_dismisses={click_outside_dismisses}>{element}</Modal>
        );
        ReactDOM.render(
            // Any valid React child: JSX, strings, arrays, etc.
            modalElement,
            // A DOM element
            modalRoot,
        );
        let webappRoot = document.getElementById('app-root');
        webappRoot.classList.toggle('pointer-events-none');
        let modal = document.getElementById('modalFrame');
        modal.classList.toggle('opacity-0')
    }
    
    static removeModalElement() {
        let webappRoot = document.getElementById('app-root');
        webappRoot.classList.toggle('pointer-events-none');
        let modalRoot = document.getElementById('modal-root');
        ReactDOM.unmountComponentAtNode(modalRoot);
    }

    constructor(props) {
        super(props)
        this.state = {click_outside_dismisses: props.click_outside_dismisses};
    }
    
    state = {
        click_outside_dismisses: true
    }

    dismissModal() {
        if (this.props.click_outside_dismisses) {
            Modal.removeModalElement();
        }
    }

    render() {

        let backgroundClasses = "modal-overlay absolute w-full h-full bg-black opacity-25 top-0 left-0";
        if (this.props.click_outside_dismisses) {
            backgroundClasses = backgroundClasses + " cursor-pointer";
        }

        let modalContainer = (
            <div id="modalFrame" className="modal opacity-0 transition-opacity duration-500 ease-in-out absolute w-full h-full top-0 left-0 flex items-center justify-center">
                <div className={backgroundClasses} onClick={() => this.dismissModal()}></div>
                <div className="absolute w-1/2 bg-white rounded-sm shadow-lg flex">
                    {this.props.children}
                </div>
            </div>
        );

        // Use a portal to render the children into the element
        return modalContainer;
    }
};

export default Modal;
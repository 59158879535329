import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../global/utility';

const initialState = {
    publishing_slides: false,
    publish_result: null
}


const publishSlideshowStart = (state, action) => {
    return updateObject(state, { publishing_slides: true, publish_result: null });
};

const publishSlideshowSuccess = (state, action) => {
    return updateObject(state, { publishing_slides: false, publish_result: action.details });
};

const publishSlideshowFailure = (state, action) => {
    return updateObject(state, { publishing_slides: false, publish_result: null });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUBLISH_SLIDESHOW_START: return publishSlideshowStart(state, action);            
        case actionTypes.PUBLISH_SLIDESHOW_SUCCESS: return publishSlideshowSuccess(state, action);
        case actionTypes.PUBLISH_SLIDESHOW_FAILURE: return publishSlideshowFailure(state, action);
        default: return state;
    }
};

export default reducer;
import * as actionTypes from './actionTypes';
import axios from '../../global/axios';

export const fetchPermissionsLevelsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_SUCCESS,
        permissions_levels: details
    }
};

export const fetchPermissionsLevelsFailure = (error) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_FAILURE,
        error: error
    };
};

export const fetchPermissionsLevelsStart = () => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_START
    }
};

export const fetchPermissionsLevels = (auth_token) => {
    return dispatch => {
        dispatch(fetchPermissionsLevelsStart());
        
        axios.get('/permissions_levels.json?auth_token=' + auth_token)
        .then(res => {
            
            dispatch(fetchPermissionsLevelsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchPermissionsLevelsFailure(err));
            alert(err.message);
        });
    }
}


export const fetchSelectedPermissionsLevelSuccess = (details) => {
    return {
        type: actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_SUCCESS,
        permissions_level: details
    }
};

export const fetchSelectedPermissionsLevelFailure = (error) => {
    return {
        type: actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_FAILURE,
        error: error
    };
};

export const fetchSelectedPermissionsLevelStart = () => {
    return {
        type: actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_START
    }
};

export const fetchSelectedPermissionsLevel = (auth_token, level_id) => {
    return dispatch => {
        dispatch(fetchSelectedPermissionsLevelStart());
        
        if (level_id == null) {
            dispatch(fetchSelectedPermissionsLevelSuccess({ details: {"name":"", "description":""} }));
            return;
        }

        axios.get('/permissions_levels/' + level_id + '.json?auth_token=' + auth_token)
        .then(res => {
            
            dispatch(fetchSelectedPermissionsLevelSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchSelectedPermissionsLevelFailure(err));
            alert(err.message);
        });
    }
}


export const saveSelectedPermissionsLevelSuccess = (details) => {
    return {
        type: actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_SUCCESS,
        permissions_level: details
    }
};

export const saveSelectedPermissionsLevelFailure = (error) => {
    return {
        type: actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_FAILURE,
        error: error
    };
};

export const saveSelectedPermissionsLevelStart = () => {
    return {
        type: actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_START
    }
};

export const saveSelectedPermissionsLevel = (auth_token, level, callback) => {
    return dispatch => {
        dispatch(saveSelectedPermissionsLevelStart());
        
        console.log(level);
        let data = {"permissions_level":level};
        let url = '/permissions_levels.json?auth_token=' + auth_token;
        if (level.id != null) {
            url = '/permissions_levels/' + level.id + '.json?auth_token=' + auth_token;
        }

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log(res.data);
            dispatch(saveSelectedPermissionsLevelSuccess(res.data));
            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(saveSelectedPermissionsLevelFailure(err));
            alert(err.message);
        });
        
    }
}


export const deleteSelectedPermissionsLevelSuccess = (details) => {
    return {
        type: actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_SUCCESS,
        permissions_level: null
    }
};

export const deleteSelectedPermissionsLevelFailure = (error) => {
    return {
        type: actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_FAILURE,
        error: error
    };
};

export const deleteSelectedPermissionsLevelStart = () => {
    return {
        type: actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_START
    }
};

export const deleteSelectedPermissionsLevel = (auth_token, level_id, callback) => {
    return dispatch => {
        dispatch(deleteSelectedPermissionsLevelStart());
        
        axios.delete('/permissions_levels/' + level_id + '.json?auth_token=' + auth_token)
        .then(res => {
            dispatch(deleteSelectedPermissionsLevelSuccess(res.data));
            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(deleteSelectedPermissionsLevelFailure(err));
            alert(err.message);
        });
    }
}


export const reorderPermissionsLevels = (auth_token, permissions_objects) => {
    return dispatch => {

        let data = {"permissions":permissions_objects};
        let url = '/permissions_levels/reorder.json?auth_token=' + auth_token;
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log(res.data);
            dispatch(saveSelectedPermissionsLevelSuccess(res.data));
        })
        .catch(err => {
            dispatch(saveSelectedPermissionsLevelFailure(err));
            alert(err.message);
        });
    }
}


export const fetchPermissionsLevelPropertyDescriptionsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_SUCCESS,
        permissions_levels: details
    }
};

export const fetchPermissionsLevelPropertyDescriptionsFailure = (error) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_FAILURE,
        error: error
    };
};

export const fetchPermissionsLevelPropertyDescriptionsStart = () => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_START
    }
};

export const fetchPermissionsLevelPropertyDescriptions = (auth_token) => {
    return dispatch => {
        dispatch(fetchPermissionsLevelPropertyDescriptionsStart());
        
        axios.get('/permissions_level_property_descriptions.json?auth_token=' + auth_token)
        .then(res => {
            
            dispatch(fetchPermissionsLevelPropertyDescriptionsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchPermissionsLevelPropertyDescriptionsFailure(err));
            alert(err.message);
        });
    }
}



export const fetchCurrentUserSuccess = (details) => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
        details: details
    }
};

export const fetchCurrentUserFailure = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_FAILURE,
        error: error
    };
};

export const fetchCurrentUserStart = () => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_START
    }
};

export const fetchCurrentUser = (auth_token) => {
    return dispatch => {
        dispatch(fetchCurrentUserStart());
        
        if (auth_token == null) {
            dispatch(fetchCurrentUserFailure(null));
            return;
        }

        axios.get('/me.json?auth_token=' + auth_token)
        .then(res => {
            console.log(res.data);
            dispatch(fetchCurrentUserSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchCurrentUserFailure(err));
            alert(err.message);
        });
    }
}


export const fetchUsersSuccess = (details) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: details
    }
};

export const fetchUsersFailure = (error) => {
    return {
        type: actionTypes.FETCH_USERS_FAILURE,
        error: error
    };
};

export const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    }
};

export const fetchUsers = (auth_token) => {
    return dispatch => {
        dispatch(fetchUsersStart());
        
        axios.get('/users.json?auth_token=' + auth_token)
        .then(res => {
            const fetchedUsers = [];
            for (let key in res.data) {
                let imageUrl = (res.data[key].user.photo_url ? res.data[key].user.photo_url : "https://worshipkit.s3.amazonaws.com/assets/img/standin_profile.png");
                fetchedUsers.push({
                    ...res.data[key],
                    link: '/users/' + res.data[key].user.id,
                    icon: imageUrl,
                    name: res.data[key].user.first_name + " " + res.data[key].user.last_name
                });
            }
            dispatch(fetchUsersSuccess(fetchedUsers));
        })
        .catch(err => {
            dispatch(fetchUsersFailure(err));
            alert(err.message);
        });
        
    }
}



export const fetchUserDetailsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
        details: details
    }
};

export const fetchUserDetailsFailure = (error) => {
    return {
        type: actionTypes.FETCH_USER_DETAILS_FAILURE,
        error: error
    };
};

export const fetchUserDetailsStart = () => {
    return {
        type: actionTypes.FETCH_USER_DETAILS_START
    }
};

export const fetchUserDetails = (user_id, auth_token, callback) => {
    return dispatch => {
        dispatch(fetchUserDetailsStart());
        
        axios.get('/users/' + user_id + '.json?auth_token=' + auth_token)
        .then(res => {
            console.log(res.data)
            dispatch(fetchUserDetailsSuccess(res.data));
            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(fetchUserDetailsFailure(err));
            alert(err.message);
        });
    }
}


export const saveUserDetailsSuccess = (details) => {
    return {
        type: actionTypes.SAVE_USER_DETAILS_SUCCESS,
        details: details
    }
};

export const saveUserDetailsFailure = (error) => {
    return {
        type: actionTypes.SAVE_USER_DETAILS_FAILURE,
        error: error
    };
};

export const saveUserDetailsStart = () => {
    return {
        type: actionTypes.SAVE_USER_DETAILS_START
    }
};

export const saveUserDetails = (user, auth_token, callback) => {
    return dispatch => {
        dispatch(saveUserDetailsStart());
        
        let data = {"user":user};
        let url = '/users/' + user.id + '.json?auth_token=' + auth_token;
        if (user.id == null) {
            url = '/users.json?auth_token=' + auth_token;
        }
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            
            dispatch(saveUserDetailsSuccess(res.data));
            dispatch(fetchUsers());
            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(saveUserDetailsFailure(err));
            alert(err.message);
        });
    }
}


export const deleteUserSuccess = (details) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS
    }
};

export const deleteUserFailure = (error) => {
    return {
        type: actionTypes.DELETE_USER_FAILURE,
        error: error
    };
};

export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START
    }
};

export const deleteUser = (user_id, auth_token, callback) => {
    return dispatch => {
        dispatch(deleteUserStart());
        
        axios.delete('/users/' + user_id + '.json?auth_token=' + auth_token)
        .then(res => {
            console.log(res.data);
            dispatch(deleteUserSuccess(res.data));
            
            dispatch(fetchUsers());
            dispatch(() => {
                if (callback) {
                    callback();
                }
            })         
        })
        .catch(err => {
            console.log(err);
            dispatch(deleteUserFailure(err));
            alert(err.message);
        });
    }
}




export const fetchSelectedUserPermissionsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_SELECTED_USER_PERMISSIONS_SUCCESS,
        details: details
    }
};

export const fetchSelectedUserPermissionsFailure = (error) => {
    return {
        type: actionTypes.FETCH_SELECTED_USER_PERMISSIONS_FAILURE,
        error: error
    };
};

export const fetchSelectedUserPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_SELECTED_USER_PERMISSIONS_START
    }
};

export const fetchSelectedUserPermissions = (user_id, auth_token) => {
    return dispatch => {
        dispatch(fetchSelectedUserPermissionsStart());
        
        axios.get('/users/' + user_id + '/permissions.json?auth_token=' + auth_token)
        .then(res => {
            dispatch(fetchSelectedUserPermissionsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchSelectedUserPermissionsFailure(err));
            alert(err.message);
        });
    }
}


export const fetchCurrentUserPermissionsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_PERMISSIONS_SUCCESS,
        details: details
    }
};

export const fetchCurrentUserPermissionsFailure = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_PERMISSIONS_FAILURE,
        error: error
    };
};

export const fetchCurrentUserPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_CURRENT_USER_PERMISSIONS_START
    }
};

export const fetchCurrentUserPermissions = (auth_token) => {
    return dispatch => {
        dispatch(fetchCurrentUserPermissionsStart());
        
        axios.get('/users/current_user_permissions.json?auth_token=' + auth_token)
        .then(res => {
            dispatch(fetchCurrentUserPermissionsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchCurrentUserPermissionsFailure(err));
            alert(err.message);
        });
    }
}


export const saveUserPermissionsSuccess = (details) => {
    return {
        type: actionTypes.SAVE_USER_PERMISSIONS_SUCCESS,
        details: details
    }
};

export const saveUserPermissionsFailure = (error) => {
    return {
        type: actionTypes.SAVE_USER_PERMISSIONS_FAILURE,
        error: error
    };
};

export const saveUserPermissionsStart = () => {
    return {
        type: actionTypes.SAVE_USER_PERMISSIONS_START
    }
};

export const saveUserPermissions = (permissions, user, auth_token) => {
    return dispatch => {
        dispatch(saveUserPermissionsStart());

        console.log("saving permissions: " + permissions + " for user: " + user);
        
        let data = {"permissions":permissions};
        let url = '/users/' + user.id + '/save_permissions.json?auth_token=' + auth_token;
        
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            
            dispatch(saveUserPermissionsSuccess(res.data));
        })
        .catch(err => {
            dispatch(saveUserPermissionsFailure(err));
            alert(err.message);
        });
    }
}




export const updateAuthTokenSuccess = (details) => {
    console.log("auth token: " + details.auth_token)
    return {
        type: actionTypes.UPDATE_TOKEN,
        details: details
    }
};

export const updateAuthToken = () => {
    return dispatch => {
        
        axios.get('/billing/auth_token.json')
        .then(res => {
            dispatch(updateAuthTokenSuccess(res.data));

            dispatch(updateOrganization(res.data.auth_token));
            dispatch(fetchCurrentUser(res.data.auth_token));
        });
    }
}


export const updateOrganizationSuccess = (details) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION,
        details: details
    }
};

export const updateOrganization = (auth_token) => {
    return dispatch => {
        
        axios.get('/organizations/current.json?auth_token=' + auth_token)
        .then(res => {
            dispatch(updateOrganizationSuccess(res.data));
        });
    }
}
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as usersActions from '../../actions/index';

import { updateObject } from '../../../global/utility';

import LoadingIndicator from '../../../global/loading_indicator/loading_indicator';
import Button from '../../../global/button/button';
import UserGeneralInfo from '../user_general_info/user_general_info';

class UserDetailViewer extends Component {

    state = {
        
    }

    componentDidMount() {
        this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
        this.props.onFetchCurrentUserPermissions(this.props.auth_token);
        console.log(this.props.match.params.id);
        this.setState({});
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
            this.props.onFetchCurrentUserPermissions(this.props.auth_token);
            console.log(this.props.match.params.id);
            // this.setState(updateObject(this.state, {selectedPlanId: null}));
        }
    }
    
    deleteUser() {
        this.props.onDeleteUser(this.props.selected_user.id, this.props.auth_token, () => {
            this.props.history.push("/users");
        });
    }

    editUser() {
        this.props.history.push("/users/" + this.props.match.params.id + "/edit");
    }

    editUserPermissions() {
        this.props.history.push("/users/" + this.props.match.params.id + "/permissions");
    }

    render () {
        
        let divClasses = "border-t-4 border-white pt-4";
        
        let contents = (
            <LoadingIndicator />
        );

        let canEdit = false;
        let canEditPermissions = false;
        let canDelete = false;

        let selfUserDescription = (
            <div></div>
        );

        if (this.props.current_user && this.props.selected_user && this.props.current_user_permissions) {

            if (this.props.current_user_permissions.organization_permissions.permissions_level) {
                canEdit = this.props.current_user_permissions.organization_permissions.permissions_level.edit_users;
                canDelete = canEdit;
                canEditPermissions = this.props.current_user_permissions.organization_permissions.permissions_level.edit_permissions;
            }

            // if current user is this user, or current user doesn't have editing rights, disable the ability to modify.
            // in the future, we might need to fork this behavior out with variations, but this is adequate for now.
            if (this.props.current_user.id == this.props.match.params.id) {
                canDelete = false;
                canEdit = true;
                canEditPermissions = false;
                selfUserDescription = <div><p>You cannot delete yourself or modify your own permissions.</p></div>;
            }

            if (!this.props.loading_selected_user) {
                
                let deleteButton = (
                    <div>
                        <Button text="Delete User" clickEvent={() => this.deleteUser()} />
                    </div>
                );

                if (!this.props.selected_user.id) {
                    deleteButton = (
                        <div></div>
                    )
                }

                if (!canDelete) {
                    deleteButton = (
                        <div></div>
                    );
                }


                let permissionsButton = <Button text="Permissions..." clickEvent={() => this.editUserPermissions()} />;

                if (!canEditPermissions) {
                    permissionsButton = (
                        <div></div>
                    );
                }

                let properties = (
                    <div>
                        {permissionsButton}
                    </div>
                );

                contents = (
                    
                    <div className={divClasses}>
                        <UserGeneralInfo user={this.props.selected_user} clickEvent={() => {this.editUser()}} />
                        
                        {properties}
                        {deleteButton}
                        {selfUserDescription}
                    </div>
                );
            }
        }
        
        
        return contents;
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        loading_selected_user: state.users.loading_selected_user,
        selected_user: state.users.selected_user,
        loading_current_user: state.users.loading_current_user,
        current_user: state.users.current_user,
        loading_current_user_permissions: state.users.loading_current_user_permissions,
        current_user_permissions: state.users.current_user_permissions,
        auth_token: state.users.auth_token
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCurrentUser: (auth_token) => dispatch(usersActions.fetchCurrentUser(auth_token)),
        onFetchUserDetails: (user_id, auth_token) => dispatch(usersActions.fetchUserDetails(user_id, auth_token)),
        onDeleteUser: (user_id, auth_token, callback) => dispatch(usersActions.deleteUser(user_id, auth_token, callback)),
        onFetchCurrentUserPermissions: (auth_token) => dispatch(usersActions.fetchCurrentUserPermissions(auth_token)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserDetailViewer);
import React, {useState, useRef} from 'react';
import { useDrag, useDrop } from 'react-dnd'
import {matchPath} from 'react-router';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

import { updateObject } from '../../../global/utility';
import Button from '../../../global/button/button';

import ItemTypes from '../item_types';

const UsersPermissionRow = ({editEvent, level, propertyDetails, movePermission, dropPermission}) => {

    const [isExpanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        let opposite = !isExpanded;
        setExpanded(opposite);
    }

    let opacity = 1;

    let mainCursor = "cursor-default"

    const ref = useRef(null)
    if (movePermission && dropPermission) {
        mainCursor = "hover:bg-gray-200 cursor-move";

        const [, drop] = useDrop({
            accept: ItemTypes.PERMISSION,
            hover(item, monitor) {
                if (!ref.current) {
                    return
                }
                // console.log("drag item: " + JSON.stringify(item));
                const dragIndex = item.index;
                const hoverIndex = level.sequence_index;
                // Don't replace items with themselves
                if (dragIndex === hoverIndex) {
                    return
                }
                // Determine rectangle on screen
                const hoverBoundingRect = ref.current.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }

                // Time to actually perform the action
                movePermission(dragIndex, hoverIndex);
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                
            },
            
        })

        let item_index = level.sequence_index;
        const [{ isDragging }, drag] = useDrag({
            item: { type: ItemTypes.PERMISSION, object: level, index: item_index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
            end(item, monitor) {
                if (!ref.current) {
                    return
                }

                const { id: droppedId, originalIndex } = monitor.getItem()
                const didDrop = monitor.didDrop()
                if (didDrop) {
                    // moveCard(droppedId, originalIndex)

                    console.log("drop item: " + JSON.stringify(item));

                    dropPermission();
                }

                return;

            }
        })
        opacity = isDragging ? 0 : 1;

        drag(drop(ref))
    }

    

    let propertyItems = [];
    propertyDetails.forEach(element => {
        let item = {"title":element.title, "id":element.id};
        if (level[element.key] == true) {
            item["description"] = element.enabled_description.replace("{user}", "User");
            propertyItems.push(item);
        } else {
            // item["description"] = element.disabled_description.replace("{user}", "User");
        }
        
    });

    let propertiesMarkup = (
        <div>

        </div>
    );

    let bottomBar = (
        <div className="w-full h-4 bg-gray-200 hover:bg-gray-300 cursor-pointer">
            <div className="pointer-events-none object-center inset-y-0 w-full px-2 text-gray-700">
                <svg className="fill-current self-center h-4 w-4 float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                <p className="uppercase text-xs"><small>Details</small></p>
            </div>
        </div>
    )

    if (isExpanded) {
        bottomBar = (
            <div></div>
        )
        propertiesMarkup = (
            <div className="flex flex-wrap bg-white rounded shadow px-2 leading-normal py-1 cursor-pointer">
                {propertyItems.map((propertyItem) => (
                    <div key={propertyItem.id} className="w-full sm:w-1/2 mb-2 pb-1">
                        <div className="px-6 py-2">
                            <div className="font-bold text-sm mb-2">{propertyItem.title}</div>
                            <p className="text-gray-700 text-xs">{propertyItem.description}</p>
                        </div>
                    </div>
                ))}
                <div className="clearfix"></div>
                <div className="w-full h-4 bg-white hover:bg-gray-100 cursor-pointer">
                    <div className="pointer-events-none object-center inset-y-0 w-full px-2 text-gray-700">
                    <svg className="fill-current self-center h-4 w-4 float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="m9.293,12.95l0.707,0.707l5.657,-5.657l-1.414,-1.414l-4.243,4.242l-4.243,-4.242l-1.414,1.414l4.95,4.95z" fill="black" id="svg_1" transform="rotate(-180 10 10.1215)"/></svg>
                        <p className="uppercase text-xs"><small>Close details</small></p>
                    </div>
                </div>
            </div>
        );
    }

    let containerClasses = "w-full rounded overflow-hidden shadow-lg mb-5 bg-gray-100 relative overflow-visible " + mainCursor;
    
    let editButton = (
        <div></div>
    );
    if (editEvent) {
        editButton = (
            <div className="float-right">
                <Button text="Edit" clickEvent={editEvent} />
            </div>
        )
    }

    return (
        <div ref={ref} style={{opacity}} className={containerClasses} onClick={() => {toggleExpanded()}}>
            
            {editButton}
            <div className="px-6 py-4 float-left">
                <div className="font-bold text-xl mb-2">{level.name}</div>
                <p className="text-gray-700 text-base">{level.description}</p>
            </div>
            <div className="clearfix"></div>
            
            {bottomBar}

            {propertiesMarkup}

            

        </div>
    );
}

export default UsersPermissionRow;
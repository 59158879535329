import * as actionTypes from './actionTypes';
import axios from '../../global/axios';


export const publishSlideshowSuccess = (details) => {
    return {
        type: actionTypes.PUBLISH_SLIDESHOW_SUCCESS,
        details: details
    }
};

export const publishSlideshowFailure = (error) => {
    return {
        type: actionTypes.PUBLISH_SLIDESHOW_FAILURE,
        error: error
    };
};

export const publishSlideshowStart = () => {
    return {
        type: actionTypes.PUBLISH_SLIDESHOW_START
    }
};

export const publishSlideshow = (auth_token, slideshow_hash) => {
    return dispatch => {
        dispatch(publishSlideshowStart());
        
        let data = JSON.stringify({
            auth_token: auth_token,
            hash: slideshow_hash
        })
        console.log(data)
        axios.post('/slides/playlists/publish.json', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            // console.log(res.data);
            dispatch(publishSlideshowSuccess(res.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(publishSlideshowFailure(err));
            alert(err.message);
        });
    }
}
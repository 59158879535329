import React, {Component} from 'react';
import { connect } from 'react-redux';

class BillingAppGeneralInfo extends Component {


    render () {

        let monthlyTotal = (this.props.plan_quantity * (this.props.plan_monthly_fee/100)).toFixed(2);

        let totalPaymentLine = null;
        if (monthlyTotal > 0) {
            totalPaymentLine = (
                <div className="my-6">
                    <p>${monthlyTotal}/ea {this.props.interval}</p>
                </div>
            );
        }

        return totalPaymentLine;
    };
};

export default BillingAppGeneralInfo;
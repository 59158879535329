import React, {Component} from 'react';
import {matchPath} from 'react-router';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

import classes from './table_row.css';

class TableRow extends Component {

    render () {

        let rowClasses = "text-gray-800 active:bg-blue-800 rounded py-4 px-6";
        if (this.props.clickEvent) {
            rowClasses = rowClasses + " cursor-pointer hover:bg-blue-100";
        } else {
            rowClasses = rowClasses + " cursor-default";
        }
        if (this.props.selected) {
            rowClasses = "cursor-default text-white bg-blue-500 rounded py-4 px-6";
        }

        let betaTag = '';
        if (this.props.beta) {
            betaTag = (
                <p className="bg-purple-400 text-white px-2 text-xs rounded float-right">BETA</p>
            );
        }

        let iconTag = '';
        if (this.props.icon) {
            iconTag = (
                <img className="h-6 bg-blue-500 float-left rounded mr-2" src={this.props.icon} />
            );
        }

        let label = (
            <p className="float-left">{this.props.text}</p>
        );
        if (this.props.detail_text) {
            label = (
                <div className="float-left">
                    <p className="text-lg">{this.props.text}</p>
                    <p className="mb-5">{this.props.detail_text}</p>
                </div>
            );
        }

        let badge = (
            <div></div>
        )
        if (this.props.badge_count > 0) {
            badge = (
                <span className="float-right ml-5 mr-5 inline-block py-0.5 px-3 text-xs text-white leading-4 font-medium rounded-full bg-red-500 transition ease-in-out duration-150 group-hover:bg-red-500 group-focus:bg-red-500">
                                            {this.props.badge_count}
                                          </span>
            )
        }

        let action = () => {
            if (this.props.clickEvent) {
                this.props.clickEvent();
            }
        }

        return (
            <div className={rowClasses} onClick={action}>
                {iconTag}
                {betaTag}
                
                {label}
                {badge}
                <div className="clearfix">&nbsp;</div>
            </div>
        );
    }
}

export default TableRow;
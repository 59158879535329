import * as actionTypes from './actionTypes';
import axios from '../../global/axios';


export const fetchMinistriesSuccess = (details) => {
    return {
        type: actionTypes.FETCH_MINISTRIES_SUCCESS,
        details: details
    }
};

export const fetchMinistriesFailure = (error) => {
    return {
        type: actionTypes.FETCH_MINISTRIES_FAILURE,
        error: error
    };
};

export const fetchMinistriesStart = () => {
    return {
        type: actionTypes.FETCH_MINISTRIES_START
    }
};

export const fetchMinistries = (auth_token, callback) => {
    return dispatch => {
        dispatch(fetchMinistriesStart());
        
        axios.get('/pager/ministries.json?auth_token=' + auth_token)
        .then(res => {
            // console.log("ministries:" + JSON.stringify(res.data));
            dispatch(fetchMinistriesSuccess(res.data));

            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(fetchMinistriesFailure(err));
            alert(err.message);
        });
    }
};



export const fetchSelectedChannelKeysSuccess = (details) => {
    return {
        type: actionTypes.FETCH_SELECTED_CHANNEL_KEYS_SUCCESS,
        details: details
    }
};

export const fetchSelectedChannelKeysFailure = (error) => {
    return {
        type: actionTypes.FETCH_SELECTED_CHANNEL_KEYS_FAILURE,
        error: error
    };
};

export const fetchSelectedChannelKeysStart = () => {
    return {
        type: actionTypes.FETCH_SELECTED_CHANNEL_KEYS_START
    }
};

export const fetchSelectedChannelKeys = (auth_token, ministry) => {
    return dispatch => {
        dispatch(fetchSelectedChannelKeysStart());

        console.log("keys loading...");

        axios.get('/pager/ministries/' + ministry.id + '/channel_keys.json?auth_token=' + auth_token)
        .then(res => {
            console.log("keys:" + JSON.stringify(res.data));
            dispatch(fetchSelectedChannelKeysSuccess(res.data));
        })
        .catch(err => {
            console.log("keys error:" + JSON.stringify(err));
            dispatch(fetchSelectedChannelKeysFailure(err));
            alert(err.message);
        });
    }
}


export const selectMinistry = (ministry) => {
    return {
        type: actionTypes.SELECT_MINISTRY,
        ministry: ministry,
    }
}



export const sendMessageSuccess = (details) => {
    return {
        type: actionTypes.SEND_MESSAGE_SUCCESS,
        details: details
    }
};

export const sendMessageFailure = (error) => {
    return {
        type: actionTypes.SEND_MESSAGE_FAILURE,
        error: error
    };
};

export const sendMessageStart = () => {
    return {
        type: actionTypes.SEND_MESSAGE_START
    }
};

export const sendMessage = (auth_token, ministry, message, callback) => {
    return dispatch => {
        dispatch(sendMessageStart());
        
        let data = JSON.stringify({
            auth_token: auth_token,
            payload: message
        })
        axios.post('/pager/ministries/' + ministry.id + '/events.json', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log("result from send message:" + JSON.stringify(res.data));
            if (res.data.result == "failure") {
                alert("Error: " + res.data.error);
            }

            dispatch(sendMessageSuccess(res.data));

            dispatch(() => {
                if (callback) {
                    callback();
                }
            })
        })
        .catch(err => {
            dispatch(sendMessageFailure(err));
            alert(err.message);
        });
    }
}
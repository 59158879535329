import React, { Component } from 'react';
import momentTZ from 'moment-timezone';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { updateObject } from '../global/utility';
import { connect } from 'react-redux';

import LoadingIndicator from '../global/loading_indicator/loading_indicator';
import Button from '../global/button/button';

import convoArtwork from './home_convo_drawing.png';
import listsArtwork from './home_lists_drawing.png';
import designArtwork from './home_design_drawing.png';

class CallToActionButton extends Component {
    render() {
        return (
            <div id={this.props.id} className="m-0 mb-10 p-2 w-full content-center text-center">
                <button id="get_started" type="submit" className="bg-blue-500 hover:bg-blue-700 cursor-pointer rounded-lg p-4 pl-8 pr-8 m-0" onClick={() => this.props.clickEvent()}><p className="text-white font-bold text-center text-lg">Get started with WorshipKit</p></button>
            </div>
        )
    }
}

class HomeIndexFeatureHeader extends Component {
    render() {
        return (
            <div className="w-full m-0 p-4 border-solid border-b-2 border-white">
                <p className="text-bold text-center text-2xl mb-10">{this.props.text}</p>
            </div>
        )
    }
}

class HomeIndexFeatureRow extends Component {

    render() {
        return (
            <div className="w-full m-0 p-4">
                <p className="text-bold text-center text-xl">{this.props.text}</p>
            </div>
        )
    }
};

const ContentBlockOrientation = {
    Left: 'Left',
    Right: 'Right'
};

class HomeIndexContentBlock extends Component {

    render() {

        var imageBlob = (
            <img src={this.props.artwork} />
        )

        var textAlign = (this.props.orientation === ContentBlockOrientation.Right) ? "right" : "left";

        var textBlob = (
            <div className="p-4">
                <p className={"text-3xl mb-10 text-blue-800 text-" + textAlign}>{this.props.title}</p>
                <p className={"text-xl mb-10 text-" + textAlign}>{this.props.body}</p>
            </div>
        )

        var leftBlob = (this.props.orientation === ContentBlockOrientation.Right) ? textBlob : imageBlob;
        var rightBlob = (this.props.orientation === ContentBlockOrientation.Right) ? imageBlob : textBlob;

        return (
            <div className="md:flex w-full">
                <div className="w-full md:w-1/2 md:float-left">
                    {leftBlob}
                </div>
                <div className="w-full md:w-1/2 md:float-right">
                    {rightBlob}
                </div>
                <div className="clearfix">&nbsp;</div>
            </div>
        )
    }
}

class HomeIndexLayout extends Component {

    startSignup() {
        window.location = "/accounts/new";
    }

    render() {
        
        let title_text_values = {
            title: "The essential communication tools for ministry",
            subtitle: "Finally a set of tools both you and your team will love",
            preamble: "You'll love it for the security and accountability. They'll love it for the easy-to-use apps."
        }

        let title_text_block = (
            <div className="p-4">
                <p style={{fontFamily: "MADE Dillan"}} className="text-6xl mb-10 text-blue-800 text-center">{title_text_values.title}</p>
                <p className="text-3xl mb-10 text-blue-800 text-center">{title_text_values.subtitle}</p>
                <p className="text-xl mb-10 text-center">{title_text_values.preamble}</p>
            </div>
        )

        return (
            
            <div className="">

                <div className="pl-20 pr-20 pt-10 pb-20 w-full flex">
                    <div id="logo-left" className="float-left w-2/6"><img className="" src="/wk_logo_accounts.png" /></div>
                    <div id="menu-right" className="float-right w-4/6 text-blue-800 font-bold text-lg">
                        <ul className="inline text-right float-right">
                            {this.props.links.map((link) => (
                                <li key={link.url} className="inline"><a href={link.url} className="m-2 p-2 bg-transparent hover:underline">{link.text}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                </div>

                <div className="m-0 p-2 w-full">

                    {title_text_block}

                </div>

                <CallToActionButton id="topcalltoaction" clickEvent={this.startSignup} />


                <div className="w-full h-10">&nbsp;</div>

                
                
            </div>
        )

    }
}


const mapStateToProps = state => {
    return {
        auth_token: state.billing.auth_token,
    };
}

const mapDispatchToProps = dispatch => {
    return { };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeIndexLayout));
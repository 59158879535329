import * as actionTypes from './actionTypes';
import axios from '../../axios';


export const updateOrganizationSuccess = (details) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION,
        details: details
    }
};

export const updateOrganization = (auth_token) => {
    return dispatch => {
        
        axios.get('/organizations/current.json?auth_token=' + auth_token)
        .then(res => {
            dispatch(updateOrganizationSuccess(res.data));
        });
    }
}




export const fetchPermissionsLevelsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_SUCCESS,
        permissions_levels: details
    }
};

export const fetchPermissionsLevelsFailure = (error) => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_FAILURE,
        error: error
    };
};

export const fetchPermissionsLevelsStart = () => {
    return {
        type: actionTypes.FETCH_PERMISSIONS_LEVELS_START
    }
};

export const fetchPermissionsLevels = (auth_token) => {
    return dispatch => {
        dispatch(fetchPermissionsLevelsStart());
        
        axios.get('/permissions_levels.json?auth_token=' + auth_token)
        .then(res => {
            
            dispatch(fetchPermissionsLevelsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchPermissionsLevelsFailure(err));
            alert(err.message);
        });
    }
}
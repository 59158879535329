import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import { updateObject } from '../../global/utility';

import * as pagerActions from '../actions/index';
import * as userActions from '../../global/user/actions/index';

import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
import Button from '../../global/button/button';
import TextField from '../../global/text_field/text_field';
import PagerSendUpdateCell from './pager_send_update_cell';

class PagerSendView extends Component {

    state = {
        page_message: "",
        messages: [],
    }

    pusher = null;
    channel = null;

    initPusher() {
        if (!this.pusher && this.props.channel_keys) {
            this.pusher = new Pusher(this.props.channel_keys.pusher_key, {
                cluster: this.props.channel_keys.cluster,
                encrypted: true
            });
        }
    }

    unsubOldChannel() {
        if (this.channel) {
            this.pusher.unsubscribe(this.channel.name)
            this.channel = null;
        }
    }

    initChannel() {
        if (this.props.channel_keys) {
            this.channel = this.pusher.subscribe(this.props.channel_keys.channel_name);
            this.channel.bind(this.props.channel_keys.message_event_name, data => {
                if (data.message == '') {
                    this.setState(updateObject(this.state, { messages: [] }));
                } else {
                    this.setState(updateObject(this.state, { messages: [...this.state.messages, data] }));
                }
                console.log("pusher paging: " + JSON.stringify(data));
            });
            this.channel.bind(this.props.channel_keys.response_event_name, data => {
                if (data.message == '') {
                    this.setState(updateObject(this.state, { messages: [] }));
                } else {
                    this.setState(updateObject(this.state, { messages: [...this.state.messages, data] }));
                }
                console.log("pusher response: " + JSON.stringify(data));
            });
        }
    }

    setTimer() {
        // clear any existing timer
        this._timer != null ? clearTimeout(this._timer) : null;
    
        // hide after `delay` milliseconds
        this._timer = setTimeout(function(){
            let updatedMessages = [];
            this.state.messages.forEach(function (item, index) {
                let oldItem = item;
                oldItem.remaining = item.remaining - 1;
                if (oldItem.remaining >= 0) {
                    updatedMessages.push(oldItem);
                }
            });
            this.setState(updateObject(this.state, {messages: updatedMessages}));
            this._timer = null;
            this.setTimer();
        }.bind(this), 1000);
    }

    componentDidMount() {
        this.setTimer();
        this.sendMessageCallback = this.sendMessageCallback.bind(this);
        this.initPusher();
    }

    componentWillUnmount() {
        clearTimeout(this._timer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.channel_keys !== this.props.channel_keys) {
            this.initPusher();
            this.unsubOldChannel();
            this.initChannel();
        }
    }

    changeHandler(event, property_name) {
        
        this.setState(updateObject(this.state, {page_message: event.target.value}));
        console.log("message: " + this.state.page_message);
    }

    clearAll() {
        this.props.onSendMessage(this.props.auth_token, this.props.selected_ministry, '', this.sendMessageCallback);
    }

    sendMessage() {
        console.log("message: " + this.state.page_message);
        this.props.onSendMessage(this.props.auth_token, this.props.selected_ministry, this.state.page_message, this.sendMessageCallback);
        
    }

    sendMessageCallback() {
        this.setState(updateObject(this.state, {page_message: ''}));
    }

    render () {
        
        let clearButton = (
            <div></div>
        )
        if (this.state.messages.length > 0) {
            clearButton = (
                <Button id="clear_button" width="w-full" text="Clear" clickEvent={() => this.clearAll()} />
            )
        }

        let content = (
            <div>
                <p className="text-gray-500 text-xl mb-2">Send message to {this.props.selected_ministry.name}</p>
                <p>Enter your message - letters or numbers - and click "Send". Your message will disappear automatically after 30 seconds.</p>
                <br />
                <TextField disabled={this.props.sending_message} label="" property_name="page_message" value={this.state.page_message} changeHandler={(event) => this.changeHandler(event, "page_message")} placeholder="Enter a message" />
                <Button id="send_button" disabled={(this.props.sending_message || this.state.page_message.length == 0)} width="w-full" text="Send" clickEvent={() => this.sendMessage()} />
                
                {clearButton}
                
                <div className="clearfix"></div>
                <div>
                    {this.state.messages.map((message) => (
                        <PagerSendUpdateCell key={message.uuid} message={message} />
                    ))}
                </div>
            </div>
        );

        if (this.props.loading_ministries) {
            content = (
                <LoadingIndicator />
            )
        }

        return content;
    }
}

const mapStateToProps = state => {
    return {
        loading_ministries: state.pager.loading_ministries,
        ministries: state.pager.ministries,
        selected_ministry: state.pager.selected_ministry,
        loading_channel_keys: state.pager.loading_channel_keys,
        channel_keys: state.pager.channel_keys,
        sending_message: state.pager.sending_message,
        auth_token: state.user.auth_token,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMinistries: (auth_token) => dispatch(pagerActions.fetchMinistries(auth_token)),
        onSendMessage: (auth_token, ministry, message, callback) => dispatch(pagerActions.sendMessage(auth_token, ministry, message, callback)),
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagerSendView));
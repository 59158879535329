import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchMinistriesSuccess = (details) => {
    return {
        type: actionTypes.FETCH_MINISTRIES_SUCCESS,
        details: details
    }
};

export const fetchMinistriesFailure = (error) => {
    return {
        type: actionTypes.FETCH_MINISTRIES_FAILURE,
        error: error
    };
};

export const fetchMinistriesStart = () => {
    return {
        type: actionTypes.FETCH_MINISTRIES_START
    }
};

export const fetchMinistries = (auth_token) => {
    return dispatch => {
        dispatch(fetchMinistriesStart());
        
        axios.get('/ministries.json?auth_token=' + auth_token)
        .then(res => {
            // console.log("ministries:" + JSON.stringify(res.data));
            dispatch(fetchMinistriesSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchMinistriesFailure(err));
            alert(err.message);
        });
    }
};
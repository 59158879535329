import React, {Component} from 'react';

class PagerSendUpdateCell extends Component {

    render() {

        let elementColor = "yellow";
        if (this.props.message.machine_name) {
            elementColor = "green";
        }
        if (this.props.elementColor) {
            elementColor = this.props.elementColor;
        }

        let divClass = "bg-" + elementColor + "-100 border-l-4 border-" + elementColor + "-400 p-4 mb-2";
        let pClass = "text-sm leading-5 text-" + elementColor + "-700";

        let message = (
            <p className={pClass}>Message sent: <span className="text-bold">{this.props.message.message}</span></p>
        );
        if (this.props.message.machine_name) {
            message = (
                <p className={pClass}>{this.props.message.machine_name} displayed message: <span className="text-bold">{this.props.message.message}</span></p>
            );
        }

        if (this.props.message.remaining < 0) {
            return (
                <div></div>
            );
        }

        return (
            <div className={divClass}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        
                    </div>
                    <div className="ml-3">
                        {message}
                    </div>
                </div>
            </div>
        );
    }
}

export default PagerSendUpdateCell;
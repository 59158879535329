import * as actionTypes from './actionTypes';
import axios from '../../global/axios';


export const fetchBillingDetailsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_BILLING_DETAILS_SUCCESS,
        details: details
    }
};

export const fetchBillingDetailsFailure = (error) => {
    return {
        type: actionTypes.FETCH_BILLING_DETAILS_FAILURE,
        error: error
    };
};

export const fetchBillingDetailsStart = () => {
    return {
        type: actionTypes.FETCH_BILLING_DETAILS_START
    }
};

export const fetchBillingDetails = () => {
    return dispatch => {
        dispatch(fetchBillingDetailsStart());
        
        axios.get('/billing/billing_details.json')
        .then(res => {
            
            dispatch(fetchBillingDetailsSuccess(res.data));
        })
        .catch(err => {
            alert(err.message);
            dispatch(fetchBillingDetailsFailure(err));
        });
    }
}


export const updateBillingDetailsSuccess = (details) => {
    return {
        type: actionTypes.UPDATE_BILLING_DETAILS_SUCCESS,
        details: details
    }
};

export const updateBillingDetailsFailure = (error) => {
    return {
        type: actionTypes.UPDATE_BILLING_DETAILS_FAILURE,
        error: error
    };
};

export const updateBillingDetailsStart = () => {
    return {
        type: actionTypes.UPDATE_BILLING_DETAILS_START
    }
};

export const updateBillingDetails = (stripe_token, auth_token) => {
    return dispatch => {
        dispatch(updateBillingDetailsStart());
        
        let data = JSON.stringify({
        })
        axios.post('/billing/update_card.json?auth_token=' + auth_token + '&stripeToken=' + stripe_token, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            dispatch(updateBillingDetailsSuccess(res.data));
            dispatch(fetchBillingDetails());
            dispatch(fetchApps());
            dispatch(fetchInactiveApps());
            alert("Payment method updated successfully. Thank you for supporting WorshipKit.")
        })
        .catch(err => {
            alert(err.message);    
            dispatch(updateBillingDetailsFailure(err));
            console.log(err.response.data);
            if( err.response && err.response.data.error){
                alert("Error updating card: " + err.response.data.error)
            } else {
                alert("Unknown error updating card. Please try again, and if the problem persists, please reach out to support@worshipkit.com")
            }
        });
    }
}


// http://wk.test/billing/available_registration_apps.json

export const fetchAvailableAppsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_AVAILABLE_APPS_SUCCESS,
        apps: details
    }
};

export const fetchAvailableAppsFailure = (error) => {
    return {
        type: actionTypes.FETCH_AVAILABLE_APPS_FAILURE,
        error: error
    };
};

export const fetchAvailableAppsStart = () => {
    return {
        type: actionTypes.FETCH_AVAILABLE_APPS_START
    }
};

export const fetchAvailableApps = () => {
    return dispatch => {
        dispatch(fetchAvailableAppsStart());
        
        axios.get('/billing/available_registration_apps.json')
        .then(res => {
            const fetchedApps = [];
            for (let key in res.data) {
                fetchedApps.push({
                    ...res.data[key],
                    link: '/billing/apps/' + res.data[key].short_name,
                    icon: res.data[key].icon_url
                });
            }
            dispatch(fetchAvailableAppsSuccess(fetchedApps));
        })
        .catch(err => {
            dispatch(fetchAvailableAppsFailure(err));
            alert(err.message);
        });
    }
}



export const fetchAppsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_APPS_SUCCESS,
        apps: details
    }
};

export const fetchAppsFailure = (error) => {
    return {
        type: actionTypes.FETCH_APPS_FAILURE,
        error: error
    };
};

export const fetchAppsStart = () => {
    return {
        type: actionTypes.FETCH_APPS_START
    }
};

export const fetchApps = () => {
    return dispatch => {
        dispatch(fetchAppsStart());
        
        axios.get('/billing/active_apps.json')
        .then(res => {
            const fetchedApps = [];
            for (let key in res.data) {
                fetchedApps.push({
                    ...res.data[key],
                    link: '/billing/apps/' + res.data[key].short_name,
                    icon: res.data[key].icon_url
                });
            }
            dispatch(fetchAppsSuccess(fetchedApps));
        })
        .catch(err => {
            dispatch(fetchAppsFailure(err));
            alert(err.message);
        });
    }
}



export const fetchInactiveAppsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_INACTIVE_APPS_SUCCESS,
        apps: details
    }
};

export const fetchInactiveAppsFailure = (error) => {
    return {
        type: actionTypes.FETCH_INACTIVE_APPS_FAILURE,
        error: error
    };
};

export const fetchInactiveAppsStart = () => {
    return {
        type: actionTypes.FETCH_INACTIVE_APPS_START
    }
};

export const fetchInactiveApps = () => {
    return dispatch => {
        dispatch(fetchInactiveAppsStart());
        
        axios.get('/billing/available_inactive_apps.json')
        .then(res => {
            const fetchedApps = [];
            for (let key in res.data) {
                fetchedApps.push({
                    ...res.data[key],
                    link: '/billing/apps/' + res.data[key].short_name,
                    icon: res.data[key].icon_url
                });
            }
            dispatch(fetchInactiveAppsSuccess(fetchedApps));
        })
        .catch(err => {
            dispatch(fetchInactiveAppsFailure(err));
            alert(err.message);
        });
    }
}


export const fetchAppDetailsSuccess = (details) => {
    return {
        type: actionTypes.FETCH_APP_DETAILS_SUCCESS,
        details: details
    }
};

export const fetchAppDetailsFailure = (error) => {
    return {
        type: actionTypes.FETCH_APP_DETAILS_FAILURE,
        error: error
    };
};

export const fetchAppDetailsStart = () => {
    return {
        type: actionTypes.FETCH_APP_DETAILS_START
    }
};

export const fetchAppDetails = (app_short_name) => {
    return dispatch => {
        dispatch(fetchAppDetailsStart());
        
        axios.get('/subscriptions/' + app_short_name + '/app_details.json')
        .then(res => {
            
            dispatch(fetchAppDetailsSuccess(res.data));
        })
        .catch(err => {
            dispatch(fetchAppDetailsFailure(err));
            alert(err.message);
        });
    }
}



export const deactivateAppSuccess = (details) => {
    return {
        type: actionTypes.DEACTIVATE_APP_SUCCESS,
        details: details
    }
};

export const deactivateAppFailure = (error) => {
    return {
        type: actionTypes.DEACTIVATE_APP_FAILURE,
        error: error
    };
};

export const deactivateAppStart = () => {
    return {
        type: actionTypes.DEACTIVATE_APP_START
    }
};

export const deactivateApp = (app_short_name) => {
    return dispatch => {
        dispatch(deactivateAppStart());
        
        axios.get('/subscriptions/' + app_short_name + '/deactivate.json')
        .then(res => {
            console.log(res.data);
            dispatch(deactivateAppSuccess(res.data));
            dispatch(fetchApps());
            dispatch(fetchInactiveApps());
        })
        .catch(err => {
            console.log(err);
            dispatch(deactivateAppFailure(err));
            alert(err.message);
        });
    }
}


export const activateAppSuccess = (details) => {
    return {
        type: actionTypes.ACTIVATE_APP_SUCCESS,
        details: details
    }
};

export const activateAppFailure = (error) => {
    return {
        type: actionTypes.ACTIVATE_APP_FAILURE,
        error: error
    };
};

export const activateAppStart = () => {
    return {
        type: actionTypes.ACTIVATE_APP_START
    }
};

export const activateApp = (app_short_name) => {
    return dispatch => {
        dispatch(activateAppStart());
        
        axios.get('/subscriptions/' + app_short_name + '/activate.json')
        .then(res => {
            console.log(res.data);
            dispatch(activateAppSuccess(res.data));
            dispatch(fetchApps());
            dispatch(fetchInactiveApps());
        })
        .catch(err => {
            console.log(err);
            dispatch(activateAppFailure(err));
            alert(err.message);
        });
    }
}


export const cancelAppSuccess = (details) => {
    return {
        type: actionTypes.CANCEL_APP_SUCCESS,
        details: details
    }
};

export const cancelAppFailure = (error) => {
    return {
        type: actionTypes.CANCEL_APP_FAILURE,
        error: error
    };
};

export const cancelAppStart = () => {
    return {
        type: actionTypes.CANCEL_APP_START
    }
};

export const cancelApp = (app_short_name, auth_token) => {
    return dispatch => {
        dispatch(cancelAppStart());
        
        let data = JSON.stringify({
            auth_token: auth_token
        })
        axios.post('/subscriptions/' + app_short_name + '/cancel.json', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log(res.data);
            dispatch(cancelAppSuccess(res.data));
            dispatch(fetchAppDetails(app_short_name));
            dispatch(fetchApps());
            dispatch(fetchInactiveApps());
        })
        .catch(err => {
            console.log(err);
            dispatch(cancelAppFailure(err));
            alert(err.message);
        });
    }
}


export const subscribeAppSuccess = (details) => {
    return {
        type: actionTypes.SUBSCRIBE_APP_SUCCESS,
        details: details
    }
};

export const subscribeAppFailure = (error) => {
    return {
        type: actionTypes.SUBSCRIBE_APP_FAILURE,
        error: error
    };
};

export const subscribeAppStart = () => {
    return {
        type: actionTypes.SUBSCRIBE_APP_START
    }
};

export const subscribeApp = (stripeToken, quantity, plan_id, coupon_code, app_short_name, auth_token) => {
    return dispatch => {
        dispatch(subscribeAppStart());
        
        let data = JSON.stringify({
            auth_token: auth_token,
            stripeToken: stripeToken,
            quantity: quantity,
            plan_id: plan_id,
            coupon_code: coupon_code
        })
        axios.post('/subscriptions/' + app_short_name + '/subscribe.json', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log(res.data);
            dispatch(subscribeAppSuccess(res.data));
            dispatch(fetchAppDetails(app_short_name));
            dispatch(fetchApps());
            dispatch(fetchInactiveApps());
        })
        .catch(err => {
            console.log(err);
            dispatch(subscribeAppFailure(err));
            alert(err.message);
        });
    }
}



export const createAccountSuccess = (details) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_SUCCESS,
        details: details
    }
};

export const createAccountFailure = (error) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_FAILURE,
        error: error
    };
};

export const createAccountStart = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_START
    }
};

export const createAccount = (selected_apps, account_info, payment_method) => {
    return dispatch => {
        dispatch(createAccountStart());
        
        let data = JSON.stringify({
            selected_apps: selected_apps,
            account_info: account_info,
            payment_method: payment_method
        })
        axios.post('/accounts.json', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            console.log(res.data);
            dispatch(createAccountSuccess(res.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(createAccountFailure(err));
            alert(err.message);
        });
    }
}



export const updateAuthTokenSuccess = (details) => {
    return {
        type: actionTypes.UPDATE_TOKEN,
        details: details
    }
};

export const updateAuthToken = () => {
    return dispatch => {
        
        axios.get('/billing/auth_token.json')
        .then(res => {
            dispatch(updateAuthTokenSuccess(res.data));
        });
    }
}
import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import AppFrameLayout from '../global/app_frame/app_frame_layout';
import SlidesLayout from './slides_layout/slides_layout';

import appFrameReducer from '../global/app_frame/app_frame_reducer';
import slidesReducer from './slides_reducer';
import usersReducer from '../global/user/user_reducer';
import organizationReducer from '../global/organization/organization_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    app_frame: appFrameReducer,
    slides: slidesReducer,
    user: usersReducer,
    organziation: organizationReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class SlidesApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppFrameLayout>
                        <SlidesLayout />
                    </AppFrameLayout>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default SlidesApp;
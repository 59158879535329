import React, {Component} from 'react';

import LoadingIndicatorImage from './ajax-loader-2.gif';

class LoadingIndicator extends Component {

    render () {

        return (
            <div className={"container mx-auto flex justify-center items-center " + this.props.className}>
                <div className="flex center align-middle content-center">
                    <img src={LoadingIndicatorImage} />
                </div>
            </div>
        );
    }
}

export default LoadingIndicator;
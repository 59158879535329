export const FETCH_MINISTRIES_START = 'FETCH_MINISTRIES_START';
export const FETCH_MINISTRIES_SUCCESS = 'FETCH_MINISTRIES_SUCCESS';
export const FETCH_MINISTRIES_FAILURE = 'FETCH_MINISTRIES_FAILURE';

export const FETCH_BILLING_DETAILS_START = 'FETCH_BILLING_DETAILS_START';
export const FETCH_BILLING_DETAILS_SUCCESS = 'FETCH_BILLING_DETAILS_SUCCESS';
export const FETCH_BILLING_DETAILS_FAILURE = 'FETCH_BILLING_DETAILS_FAILURE';

export const UPDATE_BILLING_DETAILS_START = 'UPDATE_BILLING_DETAILS_START';
export const UPDATE_BILLING_DETAILS_SUCCESS = 'UPDATE_BILLING_DETAILS_SUCCESS';
export const UPDATE_BILLING_DETAILS_FAILURE = 'UPDATE_BILLING_DETAILS_FAILURE';

export const FETCH_AVAILABLE_APPS_START = 'FETCH_AVAILABLE_APPS_START';
export const FETCH_AVAILABLE_APPS_SUCCESS = 'FETCH_AVAILABLE_APPS_SUCCESS';
export const FETCH_AVAILABLE_APPS_FAILURE = 'FETCH_AVAILABLE_APPS_FAILURE';

export const FETCH_APPS_START = 'FETCH_APPS_START';
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
export const FETCH_APPS_FAILURE = 'FETCH_APPS_FAILURE';

export const FETCH_INACTIVE_APPS_START = 'FETCH_INACTIVE_APPS_START';
export const FETCH_INACTIVE_APPS_SUCCESS = 'FETCH_INACTIVE_APPS_SUCCESS';
export const FETCH_INACTIVE_APPS_FAILURE = 'FETCH_INACTIVE_APPS_FAILURE';

export const FETCH_APP_DETAILS_START = 'FETCH_APP_DETAILS_START';
export const FETCH_APP_DETAILS_SUCCESS = 'FETCH_APP_DETAILS_SUCCESS';
export const FETCH_APP_DETAILS_FAILURE = 'FETCH_APP_DETAILS_FAILURE';

export const DEACTIVATE_APP_START = 'DEACTIVATE_APP_START';
export const DEACTIVATE_APP_SUCCESS = 'DEACTIVATE_APP_SUCCESS';
export const DEACTIVATE_APP_FAILURE = 'DEACTIVATE_APP_FAILURE';

export const ACTIVATE_APP_START = 'ACTIVATE_APP_START';
export const ACTIVATE_APP_SUCCESS = 'ACTIVATE_APP_SUCCESS';
export const ACTIVATE_APP_FAILURE = 'ACTIVATE_APP_FAILURE';

export const CANCEL_APP_START = 'CANCEL_APP_START';
export const CANCEL_APP_SUCCESS = 'CANCEL_APP_SUCCESS';
export const CANCEL_APP_FAILURE = 'CANCEL_APP_FAILURE';

export const SUBSCRIBE_APP_START = 'SUBSCRIBE_APP_START';
export const SUBSCRIBE_APP_SUCCESS = 'SUBSCRIBE_APP_SUCCESS';
export const SUBSCRIBE_APP_FAILURE = 'SUBSCRIBE_APP_FAILURE';

export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';
import React, {Component} from 'react';
import { Text, View, Pressable, StyleSheet } from 'react-native-web';
import { Button as ReactNativeButton } from 'react-native-web';
import { withRouter } from 'react-router';

class Button extends Component {
    
    render() {
        let lightMode = false;
        if (this.props.lightMode) {
            lightMode = this.props.lightMode;
        }
        let buttonColor = "rgb(66, 153, 225)";
        if (this.props.buttonColor) {
            buttonColor = this.props.buttonColor;
        }

        let floatattrib = "float-left"
        if (this.props.floatRight) {
            floatattrib = "float-right"
        }
        
        let classNames = "inline-block " + floatattrib + " bg-" + buttonColor + "-500 hover:bg-" + buttonColor + "-600 active:bg-" + buttonColor + "-800 focus:bg-" + buttonColor + "-800 text-white border-b-2 border-" + buttonColor + "-800 rounded mr-6 my-4 p-2 px-6";
        if (lightMode) {
            classNames = "inline-block float-left text-sm text-gray-600 border-gray-600 border-2 rounded mr-6 my-4 px-2";
        }
        if (this.props.disabled) {
            classNames = classNames + " opacity-50 cursor-default";
        } else {
            classNames = classNames + " cursor-pointer";
        }

        if (this.props.width) {
            classNames = classNames + " text-center " + this.props.width;
        }

        if (this.props.className) {
            classNames = classNames + " " + this.props.className;
        }

        let event = this.props.clickEvent;
        if (this.props.disabled) {
            event = () => {
                console.log("disabbled");
            };
        }

        
        let textColor = 'white';
        let opacity = 1.0;
        if (this.props.disabled) {
            textColor = 'gray';
            opacity = 0.5;
        }
        
        let buttonStyles = StyleSheet.create({
            button: {
                borderRadius: 5,
                padding: 0,
                borderWidth: 0,
                backgroundColor: buttonColor,
                opacity: opacity,
                overflow: 'hidden',
                marginBottom: 5,
            },
            text: {
                color: textColor,
                textAlign: 'center',
                fontFamily: 'Lato, Helvetica, sans-serif',
                fontSize: 14,
                padding: 10,
            }
        })

        let buttonStylesHover = StyleSheet.create({
            button: {
                borderRadius: 5,
                padding: 0,
                borderWidth: 0,
                backgroundColor: buttonColor,
                opacity: opacity - 0.2,
                overflow: 'hidden',
                marginBottom: 5,
                fontFamily: 'Lato, Helvetica, sans-serif',
            },
            text: {
                color: textColor,
                textAlign: 'center',
                fontFamily: 'Lato, Helvetica, sans-serif',
                fontSize: 14,
                padding: 10,
            }
        })

        return (
            <Pressable onPress={event} disabled={this.props.disabled}>
                {({ hovered, focused, pressed }) => {
                    let styles = (hovered)? buttonStylesHover : buttonStyles;
                    return (
                        <View nativeID={this.props.id} style={styles.button}>
                            <Text style={styles.text}>{this.props.text}</Text>
                            <View style={styles.bottomEdge}></View>
                        </View>
                    )
                }}
            </Pressable>
        )
    }
};

export default Button;
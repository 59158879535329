import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import UsersLayout from './users_layout/users_layout';
import usersReducer from './users_reducer';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    users: usersReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class UsersApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <UsersLayout />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default UsersApp;
import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../global/utility';

const initialState = {
    users: [ ],
    current_user: null,
    loading_current_user: false,
    selected_user: null,
    loading_selected_user: false,
    loading_users: true,
    loading_permissions_levels: false,
    shared_permissions_levels: [ ],
    custom_permissions_levels: [ ],
    loading_property_descriptions: false,
    permissions_level_property_descriptions: [ ],
    loading_selected_permissions_level: false,
    selected_permissions_level: { },
    loading_selected_user_permissions: false,
    loading_current_user_permissions: false,
    selected_user_permissions: null,
    current_user_permissions: null,
    auth_token: null,
    organization_id: null
}

const usersPermissionsLevelsStart = (state, action) => {
    return updateObject(state, { loading_permissions_levels: true });
};

const usersPermissionsLevelsSuccess = (state, action) => {
    console.log(action);
    return updateObject(state, { loading_permissions_levels: false, shared_permissions_levels: action.permissions_levels.shared_permissions_levels, custom_permissions_levels: action.permissions_levels.custom_permissions_levels });
};

const usersPermissionsLevelsFailure = (state, action) => {
    return updateObject(state, { loading_permissions_levels: false });
};

const selectedPermissionsLevelStart = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: true });
};

const selectedPermissionsLevelSuccess = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false, selected_permissions_level: action.permissions_level });
};

const selectedPermissionsLevelFailure = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false });
};

const saveSelectedPermissionsLevelStart = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: true });
};

const saveSelectedPermissionsLevelSuccess = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false, selected_permissions_level: action.permissions_level });
};

const saveSelectedPermissionsLevelFailure = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false });
};

const deleteSelectedPermissionsLevelStart = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: true });
};

const deleteSelectedPermissionsLevelSuccess = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false, selected_permissions_level: null });
};

const deleteSelectedPermissionsLevelFailure = (state, action) => {
    return updateObject(state, { loading_selected_permissions_level: false });
};

const permissionsLevelPropertyDescriptionsStart = (state, action) => {
    return updateObject(state, { loading_property_descriptions: true });
};

const permissionsLevelPropertyDescriptionsSuccess = (state, action) => {
    console.log(action);
    return updateObject(state, { loading_property_descriptions: false, permissions_level_property_descriptions: action.permissions_levels });
};

const permissionsLevelPropertyDescriptionsFailure = (state, action) => {
    return updateObject(state, { loading_property_descriptions: false });
};

const usersStart = (state, action) => {
    return updateObject(state, { });
};

const usersSuccess = (state, action) => {
    return updateObject(state, { users: action.users, loading_users: false });
};

const usersFailure = (state, action) => {
    return updateObject(state, { loading_users: false });
};


const currentUserStart = (state, action) => {
    return updateObject(state, { loading_current_user: true });
};

const currentUserSuccess = (state, action) => {
    let current_user = state.current_user;
    current_user = updateObject(current_user, action.details);
    return updateObject(state, { loading_current_user: false, current_user: current_user });
};

const currentUserFailure = (state, action) => {
    return updateObject(state, { loading_current_user: false });
};


const userDetailsStart = (state, action) => {
    return updateObject(state, { loading_selected_user: true });
};

const userDetailsSuccess = (state, action) => {
    return updateObject(state, { loading_selected_user: false, selected_user: action.details });
};

const userDetailsFailure = (state, action) => {
    return updateObject(state, { loading_selected_user: false });
};


const saveUserDetailsStart = (state, action) => {
    return updateObject(state, { loading_selected_user: true });
};

const saveUserDetailsSuccess = (state, action) => {
    return updateObject(state, { loading_selected_user: false });
};

const saveUserDetailsFailure = (state, action) => {
    return updateObject(state, { loading_selected_user: false });
};


const deleteUserStart = (state, action) => {
    return updateObject(state, { loading_selected_user: true });
};

const deleteUserSuccess = (state, action) => {
    return updateObject(state, { loading_selected_user: false, selected_user: null });
};

const deleteUserFailure = (state, action) => {
    return updateObject(state, { loading_selected_user: false });
};




const selectedUserPermissionsStart = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: true });
};

const selectedUserPermissionsSuccess = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: false, selected_user_permissions: action.details });
};

const selectedUserPermissionsFailure = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: false });
};

const currentUserPermissionsStart = (state, action) => {
    return updateObject(state, { loading_current_user_permissions: true });
};

const currentUserPermissionsSuccess = (state, action) => {
    return updateObject(state, { loading_current_user_permissions: false, current_user_permissions: action.details });
};

const currentUserPermissionsFailure = (state, action) => {
    return updateObject(state, { loading_current_user_permissions: false });
};


const saveUserPermissionsStart = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: true });
};

const saveUserPermissionsSuccess = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: false });
};

const saveUserPermissionsFailure = (state, action) => {
    return updateObject(state, { loading_selected_user_permissions: false });
};




const updateToken = (state, action) => {
    return updateObject(state, { auth_token: action.details.auth_token });
}

const updateOrganization = (state, action) => {
    return updateObject(state, { organization_id: action.details.id });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PERMISSIONS_LEVELS_START: return usersPermissionsLevelsStart(state, action);            
        case actionTypes.FETCH_PERMISSIONS_LEVELS_SUCCESS: return usersPermissionsLevelsSuccess(state, action);            
        case actionTypes.FETCH_PERMISSIONS_LEVELS_FAILURE: return usersPermissionsLevelsFailure(state, action);
        case actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_START: return selectedPermissionsLevelStart(state, action);
        case actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_SUCCESS: return selectedPermissionsLevelSuccess(state, action);
        case actionTypes.FETCH_SELECTED_PERMISSIONS_LEVEL_FAILURE: return selectedPermissionsLevelFailure(state, action);
        case actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_START: return saveSelectedPermissionsLevelStart(state, action);
        case actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_SUCCESS: return saveSelectedPermissionsLevelSuccess(state, action);
        case actionTypes.SAVE_SELECTED_PERMISSIONS_LEVEL_FAILURE: return saveSelectedPermissionsLevelFailure(state, action);
        case actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_START: return deleteSelectedPermissionsLevelStart(state, action);
        case actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_SUCCESS: return deleteSelectedPermissionsLevelSuccess(state, action);
        case actionTypes.DELETE_SELECTED_PERMISSIONS_LEVEL_FAILURE: return deleteSelectedPermissionsLevelFailure(state, action);
        case actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_START: return permissionsLevelPropertyDescriptionsStart(state, action);
        case actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_SUCCESS: return permissionsLevelPropertyDescriptionsSuccess(state, action);
        case actionTypes.FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_FAILURE: return permissionsLevelPropertyDescriptionsFailure(state, action);
        case actionTypes.FETCH_USERS_START: return usersStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS: return usersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAILURE: return usersFailure(state, action);
        case actionTypes.FETCH_CURRENT_USER_START: return currentUserStart(state, action);            
        case actionTypes.FETCH_CURRENT_USER_SUCCESS: return currentUserSuccess(state, action);            
        case actionTypes.FETCH_CURRENT_USER_FAILURE: return currentUserFailure(state, action);
        case actionTypes.FETCH_USER_DETAILS_START: return userDetailsStart(state, action);            
        case actionTypes.FETCH_USER_DETAILS_SUCCESS: return userDetailsSuccess(state, action);            
        case actionTypes.FETCH_USER_DETAILS_FAILURE: return userDetailsFailure(state, action);
        case actionTypes.SAVE_USER_DETAILS_START: return saveUserDetailsStart(state, action);            
        case actionTypes.SAVE_USER_DETAILS_SUCCESS: return saveUserDetailsSuccess(state, action);            
        case actionTypes.SAVE_USER_DETAILS_FAILURE: return saveUserDetailsFailure(state, action);
        case actionTypes.DELETE_USER_START: return deleteUserStart(state, action);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
        case actionTypes.DELETE_USER_FAILURE: return deleteUserFailure(state, action);
        case actionTypes.FETCH_SELECTED_USER_PERMISSIONS_START: return selectedUserPermissionsStart(state, action);            
        case actionTypes.FETCH_SELECTED_USER_PERMISSIONS_SUCCESS: return selectedUserPermissionsSuccess(state, action);            
        case actionTypes.FETCH_SELECTED_USER_PERMISSIONS_FAILURE: return selectedUserPermissionsFailure(state, action);
        case actionTypes.FETCH_CURRENT_USER_PERMISSIONS_START: return currentUserPermissionsStart(state, action);            
        case actionTypes.FETCH_CURRENT_USER_PERMISSIONS_SUCCESS: return currentUserPermissionsSuccess(state, action);            
        case actionTypes.FETCH_CURRENT_USER_PERMISSIONS_FAILURE: return currentUserPermissionsFailure(state, action);
        case actionTypes.SAVE_USER_PERMISSIONS_START: return saveUserPermissionsStart(state, action);            
        case actionTypes.SAVE_USER_PERMISSIONS_SUCCESS: return saveUserPermissionsSuccess(state, action);            
        case actionTypes.SAVE_USER_PERMISSIONS_FAILURE: return saveUserPermissionsFailure(state, action);
        case actionTypes.UPDATE_TOKEN: return updateToken(state, action);
        case actionTypes.UPDATE_ORGANIZATION: return updateOrganization(state, action);
        default: return state;
    }
};

export default reducer;
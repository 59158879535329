import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as billingActions from '../actions/index';

import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
import DetailHeader from '../../global/detail_header/detail_header';

class BillingDetails extends Component {

    state = {
        
    }

    componentDidMount() {
        this.props.onFetchBillingDetails();
    }

    updateCard(stripe_key) {
        
        let auth_token = this.props.auth_token;
        let email = null;
        if (this.props.email) {
            email = this.props.email;
        }

        let updateFunc = (token, auth_token) => {
            this.props.onUpdateBillingDetails(token, auth_token);
        };

        var changeCard_handler = StripeCheckout.configure({
            key: stripe_key,
            image: 'http://worshipkit.s3.amazonaws.com/wkicon.png',
            token: function(result_token) {
                // Use the token to create the charge with a server-side script.
                // You can access the token ID with `token.id`
              
                console.log(result_token.id);
                console.log(auth_token);
                updateFunc(result_token.id, auth_token);
            }
        });

        changeCard_handler.open({
            name: 'WorshipKit Billing',
            description: 'Update billing info',
            panelLabel: "Update card",
            zipCode: true,
            allowRememberMe: false,
            email: email
        });
    }

    render () {

        let credit_card = (
            <p className="text-lg my-12 leading-relaxed font-sans cursor-default" onClick={() => {this.updateCard(this.props.stripe_publishable)}}>No credit card on file. You can <span className="text-blue-500 cursor-pointer">add one now</span>, or when you sign up for a plan.</p>
        );
        if (this.props.payment_source.brand) {
            credit_card = (
                <div className="relative bg-white text-gray-800 rounded border shadow py-2 flex mb-4 mt-8 w-64 h-40 cursor-pointer" onClick={() => {this.updateCard(this.props.stripe_publishable)}}>
                    <div className="uppercase text-bold font-sans rounded bg-blue-500 h-4 px-2 pb-5 ml-3 text-white">{this.props.payment_source.brand}</div>
                    <div className="uppercase text-bold font-sans mt-12"><span className="text-gray-500">***********</span> {this.props.payment_source.last4}</div>
                    <div className="right-0 bottom-0 absolute mb-12 mr-2 uppercase text-bold font-sans text-gray-600">{this.props.payment_source.exp_month}/{this.props.payment_source.exp_year}</div>
                    <div className="left-0 right-0 bottom-0 absolute bg-gray-500 h-8 mb-1"></div>
                </div>
            );
        }

        let contents = (
            <LoadingIndicator />
        );
        if (!this.props.loading_payment_details) {

            let subtitle = (
                <span>To change your billing associated with this account, for instance when a staff member leaves, you can <a className="text-blue-500 visited:text-blue-500 hover:text-blue-400 hover:underline hover:bg-white cursor-pointer" onClick={() => {this.updateCard(this.props.stripe_publishable)}}>update your card</a>.</span>
            );

            contents = (
                <div>
                    
                    <DetailHeader title="Billing" subtitle={subtitle} />

                    {credit_card}
    
                    <p className="text-xs text-gray-500 font-sans py-1 cursor-default">Charges will appear as "WorshipKit LLC" on your statements. For questions about specific charges or billing, please <a className="text-blue-500 underline hover:bg-white hover:text-blue-500 hover:underline cursor-pointer" href="mailto:support@worshipkit.com">get in touch</a>.</p>
    
                </div>
            );
        }

        return contents;
    }
}

const mapStateToProps = state => {
    return {
        app_data: state.billing.apps,
        loading_app_details: state.billing.loading_app_details,
        loading_payment_details: state.billing.loading_payment_details,
        payment_source: state.billing.payment_source,
        email: state.billing.email,
        stripe_publishable: state.billing.stripe_publishable,
        auth_token: state.billing.auth_token
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchBillingDetails: () => dispatch(billingActions.fetchBillingDetails()),
        onUpdateBillingDetails: (stripe, auth) => dispatch(billingActions.updateBillingDetails(stripe, auth))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
import React, {Component} from 'react';
import { connect } from 'react-redux';

import Button from '../../../global/button/button';
import DetailHeader from '../../../global/detail_header/detail_header';
import UserAvatarControl from './user_avatar_control';

class UserGeneralInfo extends Component {
  
    clickEvent() {
        if (this.props.clickEvent) {
            this.props.clickEvent();
        }
    }

    render () {

        let paddingBottom = 4;
        if (this.props.paddingBottom) {
            paddingBottom = this.props.paddingBottom;
        }

        let textClasses = "text-lg";

        let editButton = (
            <Button text="Edit" lightMode="true" clickEvent={() => {this.clickEvent()}} />
        );

        if (!this.props.clickEvent) {
            editButton = (
                <div></div>
            );
        }

        let user_name = "";
        let photo_url = "https://worshipkit.s3.amazonaws.com/assets/img/standin_profile.png";
        if (this.props.user) {
            if (this.props.user.first_name) {
                user_name = user_name + this.props.user.first_name + " ";
            }
            if (this.props.user.last_name) {
                user_name = user_name + this.props.user.last_name;
            }
            if (this.props.user.photo_url && this.props.user.photo_url.length > 0) {
                photo_url = this.props.user.photo_url;
            }
        }

        let containerStyles = "flex items-center pb-" + paddingBottom;

        return (
            <div className={containerStyles}>
                <UserAvatarControl photo_url={photo_url} edit_photo_action={this.props.edit_photo_action} />
                <div className={textClasses}>
                    <DetailHeader title={user_name} subtitle={this.props.user.email} paddingBottom="0" />
                    {editButton}
                </div>
            </div>
        );
    };
};

export default UserGeneralInfo;
import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const updateAuthTokenSuccess = (details) => {
    console.log("auth token: " + details.auth_token)
    return {
        type: actionTypes.UPDATE_TOKEN,
        details: details
    }
};

export const updateAuthToken = (callback) => {
    return dispatch => {
        
        axios.get('/billing/auth_token.json')
        .then(res => {
            dispatch(updateAuthTokenSuccess(res.data));
            console.log("auth_token: " + res.data.auth_token);

            dispatch(() => {
                if (callback) {
                    callback(res.data.auth_token)
                }
            })
            
        });
    }
}



export const fetchUsersSuccess = (details) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: details
    }
};

export const fetchUsersFailure = (error) => {
    return {
        type: actionTypes.FETCH_USERS_FAILURE,
        error: error
    };
};

export const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    }
};

export const fetchUsers = (auth_token) => {
    return dispatch => {
        dispatch(fetchUsersStart());
        
        axios.get('/users.json?auth_token=' + auth_token)
        .then(res => {
            const fetchedUsers = [];
            for (let key in res.data) {
                let imageUrl = (res.data[key].user.photo_url ? res.data[key].user.photo_url : "https://worshipkit.s3.amazonaws.com/assets/img/standin_profile.png");
                fetchedUsers.push({
                    ...res.data[key],
                    link: '/users/' + res.data[key].user.id,
                    icon: imageUrl,
                    name: res.data[key].user.first_name + " " + res.data[key].user.last_name
                });
                console.log(JSON.stringify(fetchedUsers));
            }
            dispatch(fetchUsersSuccess(fetchedUsers));
        })
        .catch(err => {
            dispatch(fetchUsersFailure(err));
            alert(err.message);
        });
        
    }
}

import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../utility';
import { act } from 'react-test-renderer';

const initialState = {
    organization_id: null,
    organization: null,
    loading_ministries: false,
    ministries: [],
    loading_permissions_levels: false,
    shared_permissions_levels: [ ],
    custom_permissions_levels: [ ],
}



const usersPermissionsLevelsStart = (state, action) => {
    return updateObject(state, { loading_permissions_levels: true });
};

const usersPermissionsLevelsSuccess = (state, action) => {
    console.log(action);
    return updateObject(state, { loading_permissions_levels: false, shared_permissions_levels: action.permissions_levels.shared_permissions_levels, custom_permissions_levels: action.permissions_levels.custom_permissions_levels });
};

const usersPermissionsLevelsFailure = (state, action) => {
    return updateObject(state, { loading_permissions_levels: false });
};


const updateOrganization = (state, action) => {
    return updateObject(state, { organization_id: action.details.id, organization: action.details });
}


const ministriesStart = (state, action) => {
    return updateObject(state, { loading_ministries: true });
};

const ministriesSuccess = (state, action) => {
    return updateObject(state, { ministries: action.details, loading_ministries: false });
};

const ministriesFailure = (state, action) => {
    return updateObject(state, { loading_ministries: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ORGANIZATION: return updateOrganization(state, action);
        case actionTypes.FETCH_MINISTRIES_START: return ministriesStart(state, action);            
        case actionTypes.FETCH_MINISTRIES_SUCCESS: return ministriesSuccess(state, action);            
        case actionTypes.FETCH_MINISTRIES_FAILURE: return ministriesFailure(state, action);
        default: return state;
        case actionTypes.FETCH_PERMISSIONS_LEVELS_START: return usersPermissionsLevelsStart(state, action);            
        case actionTypes.FETCH_PERMISSIONS_LEVELS_SUCCESS: return usersPermissionsLevelsSuccess(state, action);            
        case actionTypes.FETCH_PERMISSIONS_LEVELS_FAILURE: return usersPermissionsLevelsFailure(state, action);
    }
};

export default reducer;
import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import PagerLayout from './pager_layout/pager_layout';
import pagerReducer from './pager_reducer';
import usersReducer from '../global/user/user_reducer';
import organizationReducer from '../global/organization/organization_reducer';

import AppFrameLayout from '../global/app_frame/app_frame_layout';
import appFrameReducer from '../global/app_frame/app_frame_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    pager: pagerReducer,
    user: usersReducer,
    organziation: organizationReducer,
    app_frame: appFrameReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class PagerApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppFrameLayout>
                        <PagerLayout />
                    </AppFrameLayout>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default PagerApp;
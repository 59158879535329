const uuid = require('uuid');

const SlideTypes = {
    Scripture: "scripture",
    Title: "title",
    Point: "point",
    CenteredText: "centered text",
    LeftText: "left-aligned text"
}

export class SlideEntry {
    constructor(text) {
        this.type = SlideTypes.CenteredText
        this.text = text
        this.reference = ""
        this.uuid = uuid.v4()
    }

    static entriesForText(text) {
        // this potentially splits the text into multiple slide entries
        var entries = []
        if (SlideEntry.textIsScripture(text)) {
            var entry = new SlideEntry(text)
            entry.type = SlideTypes.Scripture
            text.split("<br>").forEach((piece) => {
                if (/(\d{0,1}\s{0,1}[A-za-z]+\s[0-9][:][0-9]{1,4}[-]{0,1}[0-9]{0,3}\s{0,1}[(]{0,1}[A-Za-z]{3,}[)]{0,1})/.test(piece)) {
                    entry.reference = piece;
                    entry.text = text.replace(piece, "")
                }
            });
            entries.push(entry)
        } else {
            entries.push(new SlideEntry(text))
        }
        return entries
    }

    static textIsScripture(text) {
        // /\d{0,1}\s{0,1}[A-za-z]+\s[0-9][:][0-9]{1,4}[-]{0,1}[0-9]{0,3}\s{0,1}[(]{0,1}[A-Za-z]{3,}[)]{0,1}/

        return /(\d{0,1}\s{0,1}[A-za-z]+\s[0-9][:][0-9]{1,4}[-]{0,1}[0-9]{0,3}\s{0,1}[(]{0,1}[A-Za-z]{3,}[)]{0,1})/.test(text)
    }
};

export class SlideCollection {
    constructor(text) {
        var updated_text = text.replaceAll("</p><p>", "<br>")
        var split_segments = updated_text.split("<br><br>")
        var results = []
        split_segments.forEach((element) => {
            if (element.trim() != "") {
                element.split("<br><br>").forEach((break_piece) => {
                    if (break_piece.trim() != "") {
                        var snippet = break_piece.replaceAll("<p>", "").replaceAll("</p>", "")
                        if (snippet.startsWith("<br>")) {
                            snippet = snippet.replace("<br>", "")
                        }
                        var entries = SlideEntry.entriesForText(snippet)
                        entries.forEach((entry) => {
                            results.push(entry)
                        })
                    }
                })
            }
        });
        this.slides = results
        // let imageGuid = uuid.v4();
        this.uuid = uuid.v4()
    }
};

export class HTMLtoRTFConverter {
    constructor() {

    }

    static hashFromHTMLString(html_string) {
        var slide_hashes = [];
        var collection = new SlideCollection(html_string)
        collection.slides.forEach((slide) => {
            var reference = null;
            if (slide.reference) {
                reference = { text: slide.reference, modifiers: { font: null, italic: false, foreground_colour: null, underline: false } };
            }
            slide_hashes.push({ body: [ { text: slide.text, modifiers: { font: null, italic: true, foreground_colour: null, underline: false }}], reference: reference, type: slide.type })
        });
        return slide_hashes;
    }
};

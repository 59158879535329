import React, {Component} from 'react';

class SelectBox extends Component {

    // expected props: /// \
    // changeHandler: (func) (params: event, object, property_name) (required)
    // object: (obj) the object to modify on select
    // property_name: (string) the property to change on the object
    // label: (string) human-readable label
    // value: (bound string, int, etc) display value, or value of current property in object
    // options: (array) of items with a `value` and a `label`


    render() {

        let handler = (event) => this.props.changeHandler(event, this.props.object, this.props.property_name);

        return (
            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor={this.props.property_name}>
                        {this.props.label}
                    </label>
                </div>
                <div className="md:w-2/3 relative">
                    <select id={this.props.property_name} onChange={handler} value={this.props.value} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    {this.props.options.map((element) => (
                        <option key={element.value} value={element.value}>{element.label}</option>
                    ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            </div>
         );
    }
}

export default SelectBox;
import React, {Component} from 'react';

import classes from './table_container.css';


class TableContainer extends Component {

    render () {
        return (
            <div className="TableContainer">
                {this.props.children}
            </div>
        );
    }
}

export default TableContainer;
import React, { useState, Component } from 'react';
import { updateObject } from '../../global/utility';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { SlideCollection, SlideEntry, HTMLtoRTFConverter } from './slides_data';

import * as userActions from '../../users/actions/index';
import * as slidesActions from '../actions/index';

class SlideEntryView extends Component {
    
    handleChange(e) {
        console.log(e.target.value)
        if (this.props.handleChange != null) {
            this.props.handleChange(this.props.slide, e.target.value)
        }
    }

    render() {
        return (
            <div className='m-2 p-4 py-4 border border-gray-500'>
                <div>
                    <select value={this.props.slide.type} onChange={(slide, type) => {
                        this.handleChange(slide, type)
                    }}>
                        <option value="scripture">Scripture</option>
                        <option value="title">Title</option>
                        <option value="point">Point</option>
                        <option value="centered text">Centered text</option>
                        <option value="left-aligned text">Left-aligned text</option>
                    </select>
                </div>
                <p>{this.props.slide.text}</p>
            </div>
        )
    }
};

class SlideCollectionView extends Component {
    render() {
        if (this.props.collection == null) {
            return (
                <div></div>
            )
        }
        return (
            <div>
                {this.props.collection.slides.map((value) => (
                    <SlideEntryView key={value.uuid} slide={value} handleChange={(slide, type) => {
                        this.props.handleSlideTypeChange(slide, type);
                    }} />
                ))}
            </div>
        )
    }
}

class SlidesIndex extends Component {

    state = {
        value: "<p>Sermon Title</p><p><br></p><p>We are the recipients of all the good God has to offer!</p><p><br></p><p>Now Boaz had gone up to the gate and sat down there. And behold, the redeemer, of whom Boaz had spoken, came by. So Boaz said, “Turn aside, friend; sit down here.” And he turned aside and sat down. And he took ten men of the elders of the city and said, “Sit down here.” So they sat down. Then he said to the redeemer, “Naomi, who has come back from the country of Moab, is selling the parcel of land that belonged to our relative Elimelech. So I thought I would tell you of it and say, Buy it in the presence of those sitting here and in the presence of the elders of my people.’ <strong><u>If you will redeem it, redeem it.</u></strong> But if you will not, tell me, that I may know, for there is no one besides you to redeem it, and I come after you.”</p><p>And he said, <strong><u>“I will redeem it.”</u></strong></p><p>Ruth 4:1-4 (ESV)</p><p><br>Here's another line of text.</p>",
        collection: null
    }

    componentDidMount() {
        this.props.onUpdateAuthToken();
    }

    publishSlides() {
        var results = HTMLtoRTFConverter.hashFromHTMLString(this.state.value);
        // this.props.onPublishSlides(this.props.auth_token, { name: "Testing", slides: [{ body: [{text: "“You can enter God’s Kingdom only through the ", modifiers: {font: null, italic: true, foreground_colour: null, underline: false}}], reference: { text: "Ephesians 11:10 (ESV)", modifiers: {font: null, italic: false, foreground_colour: null, underline: false} }}] })
        this.props.onPublishSlides(this.props.auth_token, { name: "Testing", slides: results })
        console.log(results);
    }

    updateTextWithChange(content, delta, source, editor) {
        var collection = new SlideCollection(content)
        this.setState(updateObject(this.state, {value: content, collection: collection}))
    }

    handleSlideTypeChange(slide, newType) {
        var collection = this.state.collection;
        collection.slides.forEach(existingSlide => {
            if (existingSlide.uuid == slide.uuid) {
                existingSlide.type = newType;
            }
        });
        this.setState(updateObject(this.state, {collection: collection}))
    }

    render () {
        var modules = {
            toolbar: [
                [{ font: [] }, { size: [] }],
                [{ align: [] }, 'direction'],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean']
            ],
        };

        let linkRender = (
            <div>link: ?</div>
        );
        if (this.props.publish_result != null) {
            linkRender = (
                <div>link: <a href={this.props.publish_result.url}>Link</a></div>
            );
        }

        let content = (
            <div className="w-full min-h-screen block sm:block lg:flex">
                <div className='flex-1'>
                    <ReactQuill theme="snow" modules={modules} value={this.state.value} onChange={(content, delta, source, editor) => this.updateTextWithChange(content, delta, source, editor)} />
                </div>
                <div className='bg-gray-100 flex-1 p-2 max-w-md overflow-hidden'>
                    <div>auth: {this.props.auth_token}</div>
                    <div>results: {JSON.stringify(this.props.publish_result)}</div>
                    {linkRender}
                    <div>
                        <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out" onClick={() => this.publishSlides()}>
                            Publish
                        </button>
                    </div>
                    <div>
                        <h3>Debug</h3>
                        <p>{this.state.value}</p>
                    </div>
                    <div>
                        <p><br /></p>
                        <h3>Slide division debug</h3>
                        <SlideCollectionView collection={this.state.collection} handleSlideTypeChange={(slide, type) => {
                            this.handleSlideTypeChange(slide, type)
                        }} />
                    </div>
                </div>
            </div>
        );

        return content;
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.user.auth_token,
        publishing_slides: state.slides.publishing_slides,
        publish_result: state.slides.publish_result
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback)),
        onPublishSlides: (auth_token, slideshow_hash) => dispatch(slidesActions.publishSlideshow(auth_token, slideshow_hash)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidesIndex));
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {matchPath} from 'react-router';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as usersActions from '../actions/index';

import TextField from '../../global/text_field/text_field'
import PermissionsLevelPropertySwitch from './permissions_level_property_switch';
import Button from '../../global/button/button';
import LoadingIndicator from '../../global/loading_indicator/loading_indicator';

class EditPermissionsLevel extends Component {

    state = {
    }

    componentDidMount() {
        // this.props.onFetchPermissionsLevels(this.props.auth_token);
        this.props.onFetchPermissionsLevelPropertyDescriptions(this.props.auth_token);
        if (this.props.match.params.id) {
            this.props.onFetchSelectedPermissionsLevel(this.props.auth_token, this.props.match.params.id);
        } else {
            // this.props.selected_permissions_level = {"name":"", "description":"", "edit_org": false,
            // "edit_users": true, "view_users": true, "edit_billing": false, "view_billing": false,
            // "edit_pager_customers": true, "send_page": true, "receive_page": true,"approve_equipment_requests": true,
            // "request_equipment": true, "checkout_equipment": true, "edit_equipment": false, "edit_list": true,
            // "send_to_list": true, "view_list": true, "edit_subscribers": true, "message_subscriber_conversations": true,
            // "edit_permissions": true}
            // updateObject(this.props.selected_permissions_level, {"id":null, "name":"", "description":"", "organization_id":this.props.organization_id});
            this.props.selected_permissions_level["id"] = null;
            this.props.onFetchSelectedPermissionsLevel(this.props.auth_token, null);
        }
    }

    toggleProperty(propertyItem) {
        console.log("click " + this.props.selected_permissions_level[propertyItem.key]);
        this.props.selected_permissions_level[propertyItem.key] = !(this.props.selected_permissions_level[propertyItem.key]);
        
        this.setState({}) // TODO: figure out a better way to update these.
    }

    cancelEdits() {
        this.props.history.push("/users");
    }

    saveEdits() {
        if (this.props.selected_permissions_level.id == null) {
            this.props.selected_permissions_level.organization_id = this.props.organization_id;
        }
        this.props.onSaveSelectedPermissionsLevel(this.props.auth_token, this.props.selected_permissions_level, () => {
            this.props.history.push("/users");
        });        
    }

    deleteLevel() {
        let level_id = 0;
        if (this.props.selected_permissions_level) {
            level_id = this.props.selected_permissions_level.id;
        }

        this.props.onDeleteSelectedPermissionsLevel(this.props.auth_token, level_id, () => {
            this.props.history.push("/users");
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        
        this.props.selected_permissions_level[inputIdentifier] = event.target.value;

        this.setState({});
    }

    render() {

        if (this.props.loading_selected_permissions_level) {
            return (
                <LoadingIndicator />
            );
        }

        let idString = "";
        if (this.props.match.params.id) {
            idString = (
                <div>
                    
                </div>
            );
        }

        let detailsMarkup = (
            <div>

            </div>
        );

        if (!this.props.loading_selected_permissions_level && this.props.selected_permissions_level) {
            
            let propertyDetails = [ ];
            if (this.props.permissions_level_property_descriptions) {
                propertyDetails = this.props.permissions_level_property_descriptions;
            }
            
            let propertyItems = [];
            propertyDetails.forEach(element => {
                let item = {"title":element.title, "id":element.id, "key":element.key};
                if (this.props.selected_permissions_level[element.key] == true) {
                    item["description"] = element.enabled_description.replace("{user}", "User");
                    item["selected"] = true;
                } else {
                    item["description"] = element.disabled_description.replace("{user}", "User");
                    item["selected"] = false;
                }
                propertyItems.push(item);
            });

            let propertiesMarkup = (
                <div className="flex flex-wrap">
                    {propertyItems.map((propertyItem) => (
                        <PermissionsLevelPropertySwitch key={propertyItem.id} propertyItem={propertyItem} selected={this.props.selected_permissions_level[propertyItem.key]} clickEvent={() => {this.toggleProperty(propertyItem)}} />
                    ))}
                </div>
            );

            let levelDescription = this.props.selected_permissions_level.description;
            if (!levelDescription) {
                levelDescription = "";
            }

            detailsMarkup = (
                <div>
                    
                    <TextField label="Name" property_name="name" value={this.props.selected_permissions_level.name} changeHandler={(event) => this.inputChangedHandler(event, "name")} />

                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="description">
                                Description
                            </label>
                        </div>
                        <div className="md:w-2/3">
                            <textarea className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="description" name="description" type="text" value={levelDescription} onChange={(event) => this.inputChangedHandler(event, "description")} />
                        </div>
                    </div>

                    {propertiesMarkup}
                </div>
            );
        }

        return (
            <div>
                <p>Editing Permissions Level</p>
                {idString}
                {detailsMarkup}

                <Button text="Save" clickEvent={() => this.saveEdits()} />
                <Button text="Cancel" buttonColor="gray" clickEvent={() => this.cancelEdits()} />
                <Button text="Delete" buttonColor="red" clickEvent={() => this.deleteLevel()} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.users.auth_token,
        loading_selected_permissions_level: state.users.loading_selected_permissions_level,
        selected_permissions_level: state.users.selected_permissions_level,
        loading_property_descriptions: state.users.loading_property_descriptions,
        permissions_level_property_descriptions: state.users.permissions_level_property_descriptions,
        organization_id: state.users.organization_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchSelectedPermissionsLevel: (auth_token, level_id) => dispatch(usersActions.fetchSelectedPermissionsLevel(auth_token, level_id)),
        onSaveSelectedPermissionsLevel: (auth_token, level, callback) => dispatch(usersActions.saveSelectedPermissionsLevel(auth_token, level, callback)),
        onDeleteSelectedPermissionsLevel: (auth_token, level_id, callback) => dispatch(usersActions.deleteSelectedPermissionsLevel(auth_token, level_id, callback)),
        onFetchPermissionsLevels: (auth_token) => dispatch(usersActions.fetchPermissionsLevels(auth_token)),
        onFetchPermissionsLevelPropertyDescriptions: (auth_token) => dispatch(usersActions.fetchPermissionsLevelPropertyDescriptions(auth_token))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EditPermissionsLevel);
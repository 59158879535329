import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import * as usersActions from '../actions/index';

import usersIcon from './users_icon_transparent.png';

import SplitView from '../../global/split_view/split_view';
import SectionHeader from '../../global/table_elements/section_header/section_header';
import TableRow from '../../global/table_elements/table_row/table_row';

import UsersPermissions from '../users_permissions/users_permissions';
import UserDetails from '../user_details/user_details';
import EditPermissionsLevel from '../edit_permissions_level/edit_permissions_level';

class UsersLayout extends Component {

    constructor(props) {
        super(props);
        this.splitView = React.createRef();
    }

    componentDidMount() {
        this.props.onUpdateAuthToken();
        this.props.onFetchUsers();
    }

    goToPage = (pageLink) => {
        this.props.history.push(pageLink);
        this.splitView.current.collapsePopover();
    }

    render () {

        let users = []

        if (this.props.users) {
            users = this.props.users;
            users.sort(function(a, b) {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
            });
        }

        let users_section = (
            <div>
                <SectionHeader text="Users" />                
                {users.map((user) => (
                    <Route key={user.id} exact path={user.link} children={({match}) => (
                        <TableRow key={user.id} selected={match} clickEvent={() => this.goToPage(user.link)} text={user.name} link={user.link} icon={user.icon} />
                    )} />
                ))}
                <p><br /></p>
                <a href="/users/invitation/new">Invite new user</a>
            </div>
        );
        if (this.props.loading_users) {
            users_section = (
                <div>

                </div>
            );
        }

        let left = (
            <div>
                <Route exact path="/users" children={({match}) => (
                    <TableRow selected={match} clickEvent={() => this.goToPage('/users')} link="/users" text="Users &amp; Permissions" icon={usersIcon} />
                )} />
                
                {users_section}
            </div>
        );

        let right = (
            <Switch>
                <Route path="/users" exact component={UsersPermissions} />
                <Route path="/users/permissions_levels" exact component={EditPermissionsLevel} />
                <Route path="/users/:id" component={UserDetails} />
            </Switch>
        );

        return (
            <SplitView ref={this.splitView} left={left} right={right} />
        );
    }
}

const mapStateToProps = state => {
    return {
        loading_users: state.users.loading_users,
        users: state.users.users,
        selected_user: state.users.selected_user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchUsers: () => dispatch(usersActions.fetchUsers()),
        // onFetchInactiveApps: () => dispatch(usersActions.fetchInactiveApps()),
        onUpdateAuthToken: () => dispatch(usersActions.updateAuthToken())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersLayout));
import React, {Component, Fragment, Suspense} from 'react';
import { Route, Switch, withRouter, Redirect, Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { connect } from 'react-redux';
import * as appFrameActions from './actions/index';
import LoadingIndicator from '../loading_indicator/loading_indicator';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class AppFrameLayout extends Component {

    componentDidMount() {
        this.props.onFetchNavigation();
    }

    containsPath(segment) {

    }

    render() {
        
        if (this.props.loading_navigation) {
            return (
                <div className="min-h-full">
                    <div as="nav" className='bg-blue-900 shadow-sm'>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16">
                                <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                            <img
                                className="h-8 w-auto lg:block"
                                src={this.props.logo ? this.props.logo : "https://worshipkit.s3.amazonaws.com/assets/img/standin_church.png"}
                            />
                            </div>
                            <div className="sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                <p key="loading" className='border-transparent text-gray-500 inline-flex items-center px-1 pt-1 text-md font-bold'>
                                
                                </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
        
            <div className="min-h-full">
                <Disclosure as="nav" className="bg-blue-900 shadow-sm">
                {({ open }) => (
                    <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="flex flex-shrink-0 items-center">
                            <img
                                className="block h-8 w-auto lg:hidden"
                                src={this.props.logo}
                            />
                            <img
                                className="hidden h-8 w-auto lg:block"
                                src={this.props.logo}
                            />
                            </div>
                            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                            {this.props.navigation.map((item) => (
                                <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                    window.location.pathname.includes(item.href)
                                    ? 'border-white text-white hover:text-white'
                                    : 'border-transparent text-white hover:border-white hover:text-white',
                                    'inline-flex items-center border-b-2 px-1 pt-1 text-md font-bold'
                                )}
                                aria-current={window.location.pathname.includes(item.href) ? 'page' : undefined}
                                >
                                {item.name}
                                </a>
                            ))}
                            </div>
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:items-center">
                            {/* <button
                            type="button"
                            className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button> */}

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                            <div>
                                <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">Open user menu</span>
                                <img className="h-8 w-8 rounded-full" src={this.props.user ? this.props.user.imageUrl : ""} alt="" />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {this.props.userNavigationSections.map((section, index) => (
                                    <div key={index} className="px-1 py-2 ">
                                    {section.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {({ active }) => (
                                                <a
                                                href={item.href}
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-md font-bold text-gray-700'
                                                )}
                                                >
                                                {item.name}
                                                </a>
                                            )}
                                        </Menu.Item>
                                    ))}
                                    </div>
                                ))}
                                </Menu.Items>
                            </Transition>
                            </Menu>
                        </div>
                        <div className="-mr-2 flex items-center sm:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-blue-900 p-2 text-white hover:bg-blue-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            )}
                            </Disclosure.Button>
                        </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pb-3 pt-2">
                        {this.props.navigation.map((item) => (
                            <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                                window.location.pathname.includes(item.href)
                                ? 'border-indigo-500 bg-indigo-50 text-white hover:text-white'
                                : 'border-transparent text-white hover:border-gray-300 hover:bg-gray-50 hover:text-white',
                                'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                            )}
                            aria-current={window.location.pathname.includes(item.href) ? 'page' : undefined}
                            >
                            {item.name}
                            </Disclosure.Button>
                        ))}
                        </div>
                        <div className="border-t border-gray-200 pb-3 pt-4">
                        <div className="flex items-center px-4">
                            <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={this.props.user ? this.props.user.imageUrl : ""} alt="" />
                            </div>
                            <div className="ml-3">
                            <div className="text-base font-medium text-white">{this.props.user ? this.props.user.name : ""}</div>
                            <div className="text-sm font-medium text-white">{this.props.user ? this.props.user.email : ""}</div>
                            </div>
                            {/* <button
                            type="button"
                            className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button> */}
                        </div>
                        <div className="mt-3 space-y-1">
                            {this.props.userNavigationSections.map((section, index) => (
                                <div key={index} className="px-1 py-1 ">
                                {section.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                                </div>
                            ))}
                        </div>
                        </div>
                    </Disclosure.Panel>
                    </>
                )}
                </Disclosure>

                <div className="p-0">
                {/* <header>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
                    </div>
                </header> */}
                <main>
                    <div className="mx-auto max-w-7xl p-0">{this.props.children}</div>
                </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading_navigation: state.app_frame.loading_navigation,
        user: state.app_frame.user,
        navigation: state.app_frame.navigation,
        userNavigationSections: state.app_frame.userNavigationSections,
        logo: state.app_frame.logo
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchNavigation: () => dispatch(appFrameActions.fetchNavigation())
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppFrameLayout));
export const FETCH_PERMISSIONS_LEVELS_START = 'FETCH_PERMISSIONS_LEVELS_START';
export const FETCH_PERMISSIONS_LEVELS_SUCCESS = 'FETCH_PERMISSIONS_LEVELS_SUCCESS';
export const FETCH_PERMISSIONS_LEVELS_FAILURE = 'FETCH_PERMISSIONS_LEVELS_FAILURE';

export const FETCH_SELECTED_PERMISSIONS_LEVEL_START = 'FETCH_SELECTED_PERMISSIONS_LEVEL_START';
export const FETCH_SELECTED_PERMISSIONS_LEVEL_SUCCESS = 'FETCH_SELECTED_PERMISSIONS_LEVEL_SUCCESS';
export const FETCH_SELECTED_PERMISSIONS_LEVEL_FAILURE = 'FETCH_SELECTED_PERMISSIONS_LEVEL_FAILURE';

export const SAVE_SELECTED_PERMISSIONS_LEVEL_START = 'SAVE_SELECTED_PERMISSIONS_LEVEL_START';
export const SAVE_SELECTED_PERMISSIONS_LEVEL_SUCCESS = 'SAVE_SELECTED_PERMISSIONS_LEVEL_SUCCESS';
export const SAVE_SELECTED_PERMISSIONS_LEVEL_FAILURE = 'SAVE_SELECTED_PERMISSIONS_LEVEL_FAILURE';

export const DELETE_SELECTED_PERMISSIONS_LEVEL_START = 'DELETE_SELECTED_PERMISSIONS_LEVEL_START';
export const DELETE_SELECTED_PERMISSIONS_LEVEL_SUCCESS = 'DELETE_SELECTED_PERMISSIONS_LEVEL_SUCCESS';
export const DELETE_SELECTED_PERMISSIONS_LEVEL_FAILURE = 'DELETE_SELECTED_PERMISSIONS_LEVEL_FAILURE';

export const FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_START = 'FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_START';
export const FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_SUCCESS = 'FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_SUCCESS';
export const FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_FAILURE = 'FETCH_PERMISSIONS_LEVEL_PROPERTY_DESCRIPTIONS_FAILURE';

export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_USER_DETAILS_START = 'FETCH_USER_DETAILS_START';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';

export const SAVE_USER_DETAILS_START = 'SAVE_USER_DETAILS_START';
export const SAVE_USER_DETAILS_SUCCESS = 'SAVE_USER_DETAILS_SUCCESS';
export const SAVE_USER_DETAILS_FAILURE = 'SAVE_USER_DETAILS_FAILURE';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_CURRENT_USER_PERMISSIONS_START = 'FETCH_CURRENT_USER_PERMISSIONS_START';
export const FETCH_CURRENT_USER_PERMISSIONS_SUCCESS = 'FETCH_CURRENT_USER_PERMISSIONS_SUCCESS';
export const FETCH_CURRENT_USER_PERMISSIONS_FAILURE = 'FETCH_CURRENT_USER_PERMISSIONS_FAILURE';

export const FETCH_SELECTED_USER_PERMISSIONS_START = 'FETCH_SELECTED_USER_PERMISSIONS_START';
export const FETCH_SELECTED_USER_PERMISSIONS_SUCCESS = 'FETCH_SELECTED_USER_PERMISSIONS_SUCCESS';
export const FETCH_SELECTED_USER_PERMISSIONS_FAILURE = 'FETCH_SELECTED_USER_PERMISSIONS_FAILURE';

export const SAVE_USER_PERMISSIONS_START = 'SAVE_USER_PERMISSIONS_START';
export const SAVE_USER_PERMISSIONS_SUCCESS = 'SAVE_USER_PERMISSIONS_SUCCESS';
export const SAVE_USER_PERMISSIONS_FAILURE = 'SAVE_USER_PERMISSIONS_FAILURE';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const UPDATE_ORGANIZATION= 'UPDATE_ORGANIZATION';
import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading_navigation: false,
    user: null,
    navigation: [],
    userNavigationSections: [],
    logo: ""
}

const navigationStart = (state, action) => {
    return updateObject(state, { loading_navigation: true });
};

const navigationSuccess = (state, action) => {
    return updateObject(state, { user: action.details.user, navigation: action.details.navigation, userNavigationSections: action.details.userNavigationSections, logo: action.details.logo, loading_navigation: false });
};

const navigationFailure = (state, action) => {
    return updateObject(state, { loading_navigation: false });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NAVIGATION_START: return navigationStart(state, action);            
        case actionTypes.FETCH_NAVIGATION_SUCCESS: return navigationSuccess(state, action);            
        case actionTypes.FETCH_NAVIGATION_FAILURE: return navigationFailure(state, action);
        default: return state;
    }
};

export default reducer;
import React, {Component} from 'react';
import axios from 'axios';

class UserAvatarControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success : false,
            url : ""
        }
    }

    handleChange = (ev) => {
        this.handleUpload(ev);
    }

    handleUpload = (ev) => {
        
        const awsUrl = 'https://worshipkit.s3.amazonaws.com/';
                
        const uuid = require('uuid');
        let imageGuid = uuid.v4();

        const FormData = require('form-data');
        
        let file = this.uploadInput.files[0];
        // Split the filename to get the name and type
        var mime_type = file.type;
        var filename = file.name;
        var extension = filename.replace(/^.*\./, '');
        // console.log("Preparing the upload");
        axios.get("/signed_urls", {
            params: {
                "doc_title": imageGuid + "." + extension, "content_type": mime_type
            }})
        .then(response => {
            console.log("success: " + JSON.stringify(response));
            
            // Put the fileType in the headers for the upload
            var options = {
                'key': response.data.key,
                'AWSAccessKeyId': response.data.AWSAccessKeyId,
                'acl': "public-read",
                "policy": response.data.policy,
                'signature': response.data.signature,
                'Content-Type': response.data["Content-Type"],
                'success_action_status': "201"
            };
            console.log(options);
            let form = new FormData();
            Object.keys(options).forEach(function(key) {
                form.append(key, options[key]);
            });
            form.append('file', file);

            axios.post(awsUrl, form, {
                headers: {
                    'Accept': "application/xml, text/xml, */*; q=0.01",
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(result => {
                console.log("Response from s3: " + JSON.stringify(result));
                
                let locationResult = awsUrl + response.data.key;
                console.log(locationResult);

                this.props.edit_photo_action(locationResult);
            })
            .catch(error => {
                alert("ERROR " + JSON.stringify(error));
                console.log(error);
            })
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })
    }

    // <%= hidden_field_tag("original_filename#{@random_id}", "") %>
    // <%= hidden_field_tag("uploaded_url#{@random_id}", "") %>

    // <form accept-charset="UTF-8" action="https://worshipkit.s3.amazonaws.com" class="direct-upload" enctype="multipart/form-data" method="post" id="upload_form<%= @random_id %>">
    //     <%= hidden_field_tag(:key, "") %>
    //     <%= hidden_field_tag(:AWSAccessKeyId, Rails.application.credentials[Rails.env.to_sym][:AWS_ACCESS_KEY_ID] %>
    //     <%= hidden_field_tag(:acl, "public-read") %>
    //     <%= hidden_field_tag(:policy, "") %>
    //     <%= hidden_field_tag(:signature, "") %>
    //     <%= hidden_field_tag("Content-Type", "image/png") %>
    //     <%= hidden_field_tag(:success_action_status, "201") %>
    //     <%= file_field_tag "file", :accept => 'image/*', id: "item_file_upload#{@random_id}" %>

    clickAction(ev) {
        console.log("click!");
        if (this.props.edit_photo_action) {
            
            this.uploadInput.click();
        }
    }

    render() {

        //edit_photo_action

        let containerStyles = "w-24 h-24 mr-5";
        let editOverlay = (
            <div></div>
        );

        let uploadForm = (
            <div></div>
        )

        if (this.props.edit_photo_action) {
            containerStyles = containerStyles + " relative cursor-pointer";
            editOverlay = (
                <div className="absolute bottom-0 p-2 w-full bg-white opacity-50">
                    <p className="uppercase text-xs font-bold text-center text-gray-900">Edit</p>
                </div>
            )

            

            uploadForm = (
                <input className="hidden" onChange={this.handleChange} ref={(ref) => { this.uploadInput = ref; }} type="file"/>
            )
        }

        return (
            <div className={containerStyles} onClick={(ev) => {this.clickAction(ev)}}>
                <img className="rounded-full" src={this.props.photo_url} />
                {editOverlay}
                {uploadForm}
            </div>
            
        )
    }
};

export default UserAvatarControl;
import React, {Component} from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover'

class SplitView extends Component {

    state = {
        isPopoverOpen: false
    }

    collapsePopover() {
        if (this.state.isPopoverOpen) {
            this.setState({ isPopoverOpen: false })
        }
    }

    render() {

        let showBorders = true;
        if (this.props.showBorders == false) {
            showBorders = false;
        }

        let leftElement = this.props.left;
        let popoverElementContent = React.cloneElement(leftElement);
        let popoverElement = (
            <div className="bg-white shadow-xl rounded w-64 p-4">
                {popoverElementContent}
            </div>
        );

        let rightElement = this.props.right;

        let containerStyles = { overflow: 'visible' }

        let shadowClasses = "";
        if (showBorders) {
            shadowClasses = "border-b-2 shadow-md "
        }

        let outerClasses = "bg-white text-gray-800 rounded " + shadowClasses + "py-2 px-6 mb-4 md:flex";

        return (
            <div className={outerClasses}>
                <div className="w-8 md:w-0 visible md:invisible">
                    <Popover isOpen={this.state.isPopoverOpen} position={['top', 'right', 'left', 'bottom']} padding={0} content={({ position, targetRect, popoverRect }) => (
                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                            position={position}
                            targetRect={targetRect}
                            popoverRect={popoverRect}
                            arrowColor={'white'}
                            arrowSize={10}>
                                {popoverElement}
                        </ArrowContainer>
                    )} onClickOutside={() => this.collapsePopover()} containerStyle={containerStyles}>
                        
                            <div className="border-0 border-none rounded-none hover:border-2 hover:border-solid hover:border-gray-500 hover:rounded-md cursor-pointer" onClick={() => this.setState({isPopoverOpen: !this.state.isPopoverOpen})}><svg height="32px" width="32px" className="opacity-50"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg></div>
                        
                    </Popover>
                </div>
                <div className="h-px md:h-auto invisible md:visible md:w-1/3 rounded my-5">
                    {leftElement}
                </div>
                <div className="p-6 ml-6 mb-10 md:mb-4 md:w-2/3">
                    {rightElement}
                </div>
            </div>
        )
    }
};

export default SplitView;
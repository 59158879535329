import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as usersActions from '../../actions/index';

import { updateObject } from '../../../global/utility';
import Button from '../../../global/button/button';
import LoadingIndicator from '../../../global/loading_indicator/loading_indicator';
import UsersAppGeneralInfo from '../user_general_info/user_general_info';
import TextField from '../../../global/text_field/text_field';
import UserGeneralInfo from '../user_general_info/user_general_info';
import DetailHeader from '../../../global/detail_header/detail_header';

class UserDetailEditor extends Component {

    state = {
        selectedPlanId: null,
        stripeToken: null,
        couponCode: ''
    }

    componentDidMount() {
        this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
        console.log(this.props.match.params.id);
        this.setState({selectedPlanId: null, stripeToken: null, couponCode: ''});
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
            console.log(this.props.match.params.id);
            this.setState(updateObject(this.state, {selectedPlanId: null}));
        }
    }

    editPhoto(photo_url) {
        this.props.selected_user.photo_url = photo_url
        this.setState({});
    }

    cancelEdits() {
        this.props.history.push("/users/" + this.props.match.params.id);
    }

    saveEdits() {
        // do saving stuff here
        this.props.onSaveUserDetails(this.props.selected_user, this.props.auth_token, () => {
            this.props.history.push("/users/" + this.props.selected_user.id);
        });
        
    }
    
    changeHandler(event, property_name) {
        this.props.selected_user[property_name] = event.target.value;

        this.setState({});
    }

    render () {
        
        let divClasses = "border-t-4 border-white pt-4";
        
        let contents = (
            <LoadingIndicator />
        );

        let editable = true;
        if (this.props.current_user) {

            

            if (!this.props.loading_selected_user) {
                
                let editableProperties = (
                    <div>
                        <TextField label="First Name" property_name="first_name" value={this.props.selected_user.first_name} changeHandler={(event) => this.changeHandler(event, "first_name")} />
                        <TextField label="Last Name" property_name="last_name" value={this.props.selected_user.last_name} changeHandler={(event) => this.changeHandler(event, "last_name")} />
                        <TextField label="Phone" property_name="phone_number" value={this.props.selected_user.phone_number} changeHandler={(event) => this.changeHandler(event, "phone_number")} />
                        <TextField label="E-mail" property_name="email" value={this.props.selected_user.email} changeHandler={(event) => this.changeHandler(event, "email")} />
                    </div>
                );

                if (!editable) {
                    editableProperties = (
                        <div></div>
                    );
                }

                

                contents = (
                    
                    <div className={divClasses}>
                        <UserGeneralInfo user={this.props.selected_user} edit_photo_action={(photo_url) => {this.editPhoto(photo_url)}} paddingBottom="10" />
                        
                        {editableProperties}
                        <Button text="Save" clickEvent={() => this.saveEdits()} />
                        <Button text="Cancel" buttonColor="gray" clickEvent={() => this.cancelEdits()} />
                    </div>
                );
            }
        }
        
        
        return contents;
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        loading_selected_user: state.users.loading_selected_user,
        selected_user: state.users.selected_user,
        loading_current_user: state.users.loading_current_user,
        current_user: state.users.current_user,
        auth_token: state.users.auth_token
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCurrentUser: (auth_token) => dispatch(usersActions.fetchCurrentUser(auth_token)),
        onFetchUserDetails: (user_id, auth_token) => dispatch(usersActions.fetchUserDetails(user_id, auth_token)),
        onSaveUserDetails: (user, auth_token, callback) => dispatch(usersActions.saveUserDetails(user, auth_token, callback))
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserDetailEditor);
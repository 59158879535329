import React, {Component} from 'react';


class DetailHeader extends Component {

    clickEvent() {
        if (this.props.link) {
            window.location.href = this.props.link;
        }
    }

    render () {

        let paddingBottom = 4;
        if (this.props.paddingBottom) {
            paddingBottom = this.props.paddingBottom;
        }

        let titleStyles = "text-2xl mb-4";
        let subtitleStyles = "text-base pb-" + paddingBottom;
        let spanStyles = "cursor-pointer bg-white hover:bg-gray-200 rounded p-2";
        if (!this.props.link) {
            spanStyles = "cursor-default";
        }

        return (
            <div onClick={() => {this.clickEvent()}}>
                <h3 className={titleStyles}><span className={spanStyles}>{this.props.title}</span></h3>
                <p className={subtitleStyles}><span className={spanStyles}>{this.props.subtitle}</span></p>
            </div>
        );
    }
}


export default DetailHeader;
import React, { Fragment, Component } from 'react';

import AppFrameLayout from './app_frame_layout'

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import appFrameReducer from './app_frame_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    app_frame: appFrameReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class AppFrame extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppFrameLayout>
                        {this.props.children}
                    </AppFrameLayout>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default AppFrame;
import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

import UserDetailViewer from '../user_details/user_detail_viewer/user_detail_viewer';
import UserDetailEditor from '../user_details/user_detail_editor/user_detail_editor';
import UserDetailPermission from '../user_details/user_detail_permission/user_detail_permission';
import EditPermissionsLevel from '../edit_permissions_level/edit_permissions_level';

class UserDetails extends Component {

    render () {
        
        const { path } = this.props.match;

        return (
            <Switch>
                <Route path={`${path}`} exact component={UserDetailViewer} />
                <Route path={`${path}/edit`} component={UserDetailEditor} />
                <Route path={`${path}/permissions`} component={UserDetailPermission} />
                <Route path={`${path}/level`} component={EditPermissionsLevel} />
            </Switch>
        );
    }
}

export default UserDetails;
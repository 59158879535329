
import React, {Component} from 'react';
import { connect } from 'react-redux';

const ClickBlockDiv = (props) => {
    return (
        <div {...props} onClick={(e) => {
            e.stopPropagation()
        }}>
            {props.children}
        </div>
    )
}

const PagerAppDetailsBox = (props) => {

    let matchingApp = props.selected_apps.find(existing_app => existing_app.id === props.app.id);
    let quantity = matchingApp.billing_calculation.selected_quantity;
    let cost_per_campus = matchingApp.billing_calculation.cost_per_campus;

    return (
        <div>
            <p>We bill Pager by the number of campuses your ministry/organization has.</p>
            <p><br /></p>
            <label className="text-lg" htmlFor="campus_count">Campus count </label><br /><input className="w-12 mt-4 mb-4 p-2 rounded border border-solid border-gray-500 text-lg" type="text" name="campus_count" placeholder="1" onChange={(e) => props.onQuantityChangeHandler(e, props.app.id)} />
            <p>${cost_per_campus.toFixed(2)} per campus, per month</p>
            <p>${(cost_per_campus * quantity).toFixed(2)}/month total</p>
        </div>
    )
}


const AppRow = (props) => {
    
    let isActive = props.is_selected;

    let rowClasses = "cursor-pointer text-gray-800 rounded my-4 py-4 px-6 border-solid border-2";
    if (isActive) {
        rowClasses = rowClasses + " border-blue-500";
    } else {
        rowClasses = rowClasses + " border-white hover:bg-gray-200";
    }

    let quantityControls = (
        <div></div>
    )
    if (isActive) {
        let appContent = (
            <div></div>
        )
        if (props.app.short_name == "pager") {
            appContent = <PagerAppDetailsBox {...props} />
        }
        
        quantityControls = (
            <ClickBlockDiv>
                {appContent}
            </ClickBlockDiv>
        )
    }

    let next_button_title_styles = "text-white font-bold text-center text-lg";
    let next_button_styles = "bg-blue-500 hover:bg-blue-400 cursor-pointer";
    next_button_styles = next_button_styles + " w-full rounded pt-4 pb-4 m-0";
    let next_button_title = (isActive) ? "Remove " + props.app.name : "Add " + props.app.name;
    let fine_print = "";

    let next_button = (
        <div className="p-4 m-0" id={"remove_" + props.app.name.toLowerCase()}>
            <div className={next_button_styles}>
                <p className={next_button_title_styles}>{next_button_title}</p>
            </div>
            {fine_print}
        </div>
    )

    let output = (
        <div className={rowClasses} onClick={() => props.changeHandler(props.app)}>
            <div className="float-left w-10 h-full rounded m-0 bg-blue-500 content-center">
                <img src={props.app.icon_url} />
            </div>
            <div className="w-full ml-10 pl-4 mt-0 mb-4">
                <p className="text-gray-700 text-xl h-10">{props.app.name}</p>
                <p>{props.app.description}</p>
                {quantityControls}
            </div>
            <div className="float-none clearfix"></div>
            {next_button}
        </div>
   );

   return output;
}

class AccountsSignupAppPickerView extends Component {

    

    componentDidMount() {
        // this.props.onFetchBillingDetails();
    }

    appSelectionChange(event_app) {
        console.log("element: " + event_app.id + " value: " + event_app.checked);

        console.log("starting selected apps: " + JSON.stringify(this.props.selected_apps));

        let matchingApp = this.props.available_apps.find(app => app.id == event_app.id);
        if (matchingApp == null || !matchingApp) {
            console.log("no match");
            return;
        }

        this.props.onAppStateChange(matchingApp);

    }

    

    render() {
        return (
            <div>

                {this.props.available_apps.map((app) => {
                    let matchingApp = this.props.selected_apps.find(sel_app => sel_app.id === app.id);
                    let is_selected = matchingApp != null;
                    return <AppRow key={app.id} is_selected={is_selected} app={app} selected_apps={this.props.selected_apps} available_apps={this.props.available_apps} changeHandler={(target) => this.appSelectionChange(target)} onQuantityChangeHandler={this.props.onQuantityChangeHandler} />
                })}
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AccountsSignupAppPickerView);
import React, {Component} from 'react';

import classes from './plan_table_row.css';

class PlanTableRow extends Component {

    render () {

        let isActive = false;
        if (this.props.activePlanId === this.props.planId) {
            isActive = true;
        }

        let rowClasses = "cursor-pointer text-gray-800 hover:bg-gray-200 rounded my-4 py-4 px-6";
        if (isActive) {
            rowClasses = "cursor-default text-gray-800 border-solid border-2 border-gray-200 rounded my-4 py-4 px-6";
        }

        let clickEvent = this.props.clickEvent;
        let selectedTag = '';

        if (this.props.selectedPlanId === this.props.planId) {
            rowClasses = "cursor-pointer text-gray-800 border-solid border-2 border-blue-500 rounded my-4 py-4 px-6";
            selectedTag = (
                <p className="bg-blue-400 text-white px-2 text-xs rounded md:float-right">NEW PLAN</p>
            );
        }

        let visible = this.props.active;

        let activeTag = '';
        if (isActive) {
            visible = true;
            clickEvent = null;
            activeTag = (
                <p className="bg-gray-400 text-white px-2 text-xs rounded md:float-right">CURRENT PLAN</p>
            );
        }

        let periodicFlatFee = null;
        if (this.props.amount > 0) {
            periodicFlatFee = (
                <p>${this.props.amount.toFixed(2)}/{this.props.term}</p>
            );
        }

        let tiersTable = null;
        if (this.props.tiers) {
            tiersTable = (
                <div>
                    <div className="border-b border-gray-300 border-solid pb-3 mb-4">
                        <h4 className="uppercase text-gray-400">Tiers</h4>
                    </div>
                    {this.props.tiers.map((tier, index) => (
                        <div key={index}>${(tier.unit_amount / 100).toFixed(2)} per unit {tier.up_to ? "up to " + tier.up_to : null}{tier.up_to > 0 && tier.flat_amount > 0 ? ", and " : null}{tier.flat_amount ? " $" + (tier.flat_amount / 100).toFixed(2) + " flat fee" : null }</div>
                    ))}
                </div>
            );
        }

        let output = (
            <div className={rowClasses} onClick={clickEvent}>
                {activeTag}
                {selectedTag}
                {periodicFlatFee}
                {tiersTable}
            </div>
        );
        if (!visible) {
            output = '';
        }

        return output;
    }
}

export default PlanTableRow;
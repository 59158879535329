import React, { Component } from 'react';
import momentTZ from 'moment-timezone';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { updateObject } from '../global/utility';
import { connect } from 'react-redux';

import * as billingActions from '../billing/actions/index';

import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';

import AccountsSignupAppPickerView from './accounts_signup_app_picker_view';
import LoadingIndicator from '../global/loading_indicator/loading_indicator';

class CheckoutForm extends React.Component {
    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
    
        const {stripe, elements, onPaymentAction} = this.props;
    
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        // const {error, paymentMethod} = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement,
        // });
        const {error, token} = await stripe.createToken(cardElement);
    
        if (error) {
            console.log('[error]', error);
        } else {
            console.log('[token]', token);
            if (onPaymentAction) {
                onPaymentAction(token);
            }
        }
    };
  
    render() {
        const { stripe } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <CardElement options={{
                                style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                    color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                                },
                            }} />
                <p><br /></p>
                <button id="submit_payment" type="submit" className="bg-blue-500 cursor-pointer w-full rounded pt-4 pb-4 m-0" disabled={!stripe}><p className="text-white font-bold text-center text-lg">Add payment method</p></button>
            </form>
        );
    }
}

class AccountsSignupLayout extends Component {

    state = {
        user_chose_apps: false,
        user_selecting_payment: false,
        selected_apps: [
        ],
        account_info: {
            church_name: "",
            full_name: "",
            email: "",
            password: "",
            timezone: momentTZ.tz.guess(),
        },
        account_info_fields: [
            {
                field_name: "church_name",
                description: "Your church or organization name",
                input_type: "text",
                placeholder: "Church Name",
            },
            {
                field_name: "full_name",
                description: "Your full name",
                input_type: "text",
                placeholder: "Full Name",
            },
            {
                field_name: "email",
                description: "Your email",
                input_type: "text",
                placeholder: "email@example.com",
            },
            {
                field_name: "password",
                description: "Choose a password",
                input_type: "password",
                placeholder: "Password, at least 8 characters, with at least one number or symbol",
            },
            {
                field_name: "timezone",
                description: "Timezone",
                input_type: "options",
                possible_options: momentTZ.tz.names(),
            }
        ]
    }

    componentDidMount() {
        this.props.onFetchAvailableApps()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.available_apps.length == 0 && this.state.selected_apps.length == 0) {
            this.setState(updateObject(this.state, {selected_apps: this.props.available_apps}))
        }
    }

    goToInfo() {
        this.setState(updateObject(this.state, { user_chose_apps: true }));
    }

    canGoToInfo() {
        return (this.state.selected_apps.length > 0);
    }

    backToApps() {
        this.setState(updateObject(this.state, { user_chose_apps: false }));
    }

    goToPayment() {
        this.setState(updateObject(this.state, { user_selecting_payment: true }));
    }

    canGoToPayment() {
        
        return (this.state.account_info.church_name.length > 0 &&
            this.state.account_info.full_name.length > 0 &&
            this.state.account_info.email.length > 0 &&
            this.state.account_info.password.length > 0 &&
            this.state.account_info.timezone.length);
    }


    backToAccountInfo() {
        this.setState(updateObject(this.state, { user_chose_apps: true, user_selecting_payment: false }));
    }

    toggledApp(app) {
        let existingMatch = this.state.selected_apps.find(existing_app => existing_app.id == app.id);
        if (existingMatch) {
            // contains it, so remove
            let apps = this.state.selected_apps;
            if (!apps) {
                apps = [];
            }
            let filtered = [];
            if (apps) {
                filtered = apps.filter(function (value, index, arr) { return value.id != existingMatch.id });
            }
            console.log("updating lists to: " + JSON.stringify(filtered))
            this.setState(updateObject(this.state, {selected_apps: filtered}))
        } else {
            // doesn't contain, so add it
            let apps = this.state.selected_apps;
            if (!apps) {
                apps = [];
            }

            let matchingApp = this.props.available_apps.find(existing_app => existing_app.id == app.id);
            if (matchingApp == null || !matchingApp) {
                console.log("no match");
                return;
            }
            
            apps.push(matchingApp);
            console.log("added list to result: " + JSON.stringify(apps));
            this.setState(updateObject(this.state, { selected_apps: apps }));
        }
    }

    onQuantityChangeHandler(event, app_id) {
        // console.log("selected apps: " + this.props.selected_apps);
        console.log("updated value: " + event.target.value);

        let updated_selected_apps = this.state.selected_apps;
        
        updated_selected_apps.forEach((app) => {
            if (app.id == app_id) {
                app.billing_calculation.selected_quantity = event.target.value;
            }
        })
        
        this.setState(updateObject(this.state, { selected_apps: updated_selected_apps }));
    }

    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
    
        const {stripe, elements} = this.props;
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (error) {
          console.log('[error]', error);
        } else {
          console.log('[PaymentMethod]', paymentMethod);
        }
    };

    onValueChange(event, field_name) {
        let account_info = this.state.account_info;
        account_info[field_name] = event.target.value;
        this.setState(updateObject(this.state, { account_info: account_info }));
    }

    fieldRender(field) {
        let input_field = (
            <input key={field.field_name} id={field.field_name} className="form-input border border-gray-400 border-solid rounded block w-full transition duration-150 ease-in-out text-2xl sm:text-lg sm:leading-5 p-4" type={field.input_type} value={this.state.account_info[field.field_name]} onChange={(e) => this.onValueChange(e, field.field_name)} placeholder={field.placeholder} />
        )
        if (field.input_type == "options") {
            input_field = (
                <select key={field.field_name} id={field.field_name} className="form-input border border-gray-400 border-solid rounded block w-full transition duration-150 ease-in-out text-2xl sm:text-lg sm:leading-5 p-2" defaultValue={this.state.account_info[field.field_name]} onChange={(e) => this.onValueChange(e, field.field_name)}>
                    {field.possible_options.map((option_value) => {
                        return (
                            <option key={option_value} value={option_value}>{option_value}</option>
                        )
                    })}
                </select>
            )
        }
        return input_field;
    }

    _createAccount() {
        this.props.onCreateAccount(this.state.selected_apps, this.state.account_info, this.state.payment_method)
    }

    onPaymentCompleted(paymentMethod) {
        console.log("payment completed with result: " + JSON.stringify(paymentMethod));
        this.setState(updateObject(this.state, { payment_method: paymentMethod, user_selecting_payment: false, user_skipped_payment: false }));

        this._createAccount()
    }

    onSkipPayment() {
        this.setState(updateObject(this.state, { user_skipped_payment: true, user_selecting_payment: false }));

        this._createAccount()
    }

    goToDashboard() {
        window.location.href = "/dash?auth_token=" + this.props.auth_token;
    }

    render() {
        /// Step 1: Choose from list of apps (all enabled by default)

        let title_text_values = {
            title: "Welcome to WorshipKit!",
            subtitle: "Only pay for what you need.",
            preamble: "Save money by only activating the services you need. No contracts, cancel anytime."
        }

        if (this.state.user_chose_apps) {
            title_text_values = {
                title: "You're almost there!",
                subtitle: "Finish setting up your account",
                preamble: "Enter your organization details, and your own info, and create a new password"
            }
        }

        if (this.state.user_selecting_payment) {
            title_text_values = {
                title: "Add a payment method",
                subtitle: "Begin your trial now",
                preamble: "To continue seamless use after your free trial, securely enter your payment information now"
            }
        }

        if (this.state.user_skipped_payment || this.state.payment_method) {
            title_text_values = {
                title: "Welcome to WorshipKit",
                subtitle: "Thank you for setting up an account",
                preamble: "Your journey to easier ministry begins now."
            }
        }

        let title_text_block = (
            <div className="p-4">
                <p style={{fontFamily: "MADE Dillan"}} className="text-4xl mb-2 text-blue-800">{title_text_values.title}</p>
                <p className="text-2xl mb-4 text-blue-800">{title_text_values.subtitle}</p>
                <p className="text-lg mb-4">{title_text_values.preamble}</p>
            </div>
        )

        let back_button = (
            <div></div>
        )

        if (this.state.user_chose_apps) {
            back_button = (
                <div className="m-4 p-2 w-auto rounded border-2 border-solid border-gray-400 text-gray-500 text-center font-bold cursor-pointer" onClick={() => this.backToApps()}>
                    <p>Back to apps</p>
                </div>
            )
        }
        if (this.state.user_selecting_payment || this.state.user_skipped_payment || this.state.payment_method) {
            back_button = (
                <div></div>
            )
        }

        let next_button_title_styles = "text-white font-bold text-center text-lg";
        let next_button_title = "Next";
        let next_button_action = () => this.goToInfo();
        let next_button_styles = "bg-blue-500 cursor-pointer";
        let fine_print = "";
        if (false == this.canGoToInfo() && !this.state.user_chose_apps && !this.state.user_selecting_payment) {
            next_button_styles = "bg-gray-500 cursor-not-allowed";
            next_button_action = () => { };
        }

        if (this.state.user_chose_apps) {
            next_button_title = "Add Payment";
            next_button_action = () => this.goToPayment();
            if (false == this.canGoToPayment()) {
                next_button_styles = "bg-gray-500 cursor-not-allowed";
                next_button_action = () => { };
            }
            fine_print = (
                <p className="pt-2 text-xs text-center text-gray-500 cursor-default">You'll be able to add payment info on the next screen.</p>
            )
        }
        if (this.state.user_selecting_payment) {
            next_button_title = "Skip for now";
            next_button_styles = "bg-white border-2 rounded border-gray-500 cursor-pointer";
            next_button_title_styles = "text-gray-500 font-bold text-center text-lg"
            next_button_action = () => this.onSkipPayment();
            fine_print = ""
        }

        if (this.state.user_skipped_payment || this.state.payment_method) {
            
            next_button_title = "Go to dashboard";
            next_button_action = () => this.goToDashboard();
        }
        
        next_button_styles = next_button_styles + " w-full rounded pt-4 pb-4 m-0";

        let next_button = (
            <div className="p-4 m-0">
                <div id="next_button" className={next_button_styles} onClick={next_button_action}>
                    <p className={next_button_title_styles}>{next_button_title}</p>
                </div>
                {fine_print}
            </div>
        )

        let app_content = <LoadingIndicator />
        if (!this.props.loading_available_apps) {
            app_content = <AccountsSignupAppPickerView available_apps={this.props.available_apps} selected_apps={this.state.selected_apps} onAppStateChange={(app) => this.toggledApp(app)} onQuantityChangeHandler={(e, id) => this.onQuantityChangeHandler(e, id)} />
        }

        let current_step = (
            <div>
                {title_text_block}

                <div className="p-4">
                    {app_content}
                </div>

                {next_button}
            </div>
        )

        if (this.state.user_chose_apps) {

            current_step = (
                <div>
                    {title_text_block}

                    <div className="p-4">
                        {this.state.account_info_fields.map((field) => {
                            
                            return (
                                <div key={field.field_name} className="mb-5">
                                    <label htmlFor={field.field_name} className="block text-lg leading-5 text-gray-700">{field.description}</label>
                                    <div className="mt-1 rounded-md shadow-sm">
                                        {this.fieldRender(field)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {next_button}
                </div>
            )
        }

        if (this.state.user_selecting_payment) {

            const {stripe} = this.props;

            current_step = (
                <div>
                    {title_text_block}

                    <div className="p-4">

                        <ElementsConsumer>
                        {({elements, stripe}) => (
                                <CheckoutForm elements={elements} stripe={stripe} onPaymentAction={(paymentMethod) => { this.onPaymentCompleted(paymentMethod) }} />
                        )}
                        </ElementsConsumer>
                        
                    </div>

                    {next_button}
                </div>
            )
        }


        let intro_and_tutorial_block = (
            <div>
                <p>auth_token: {this.props.auth_token}</p>
                <p>Thank you for signing up for WorshipKit.</p>
                <p>We've created some tutorials on <a href="https://www.youtube.com/channel/UClK4n4LCGDdTSkw7y8R1ptg" target="blank" className="underline hover:underline">our YouTube channel</a> to help you get started. You can also <a href="mailto:support@worshipkit.com" className="underline hover:underline">reach out with any questions</a>.</p>

                <div className="p-4 m-0">
                    <div id="next_button" className="bg-blue-500 cursor-pointer w-full rounded pt-4 pb-4 m-0" onClick={() => {this.goToDashboard()}}>
                        <p className="text-white font-bold text-center text-lg">Start using WorshipKit</p>
                    </div>
                </div>
                
            </div>
        )

        if (this.state.payment_method) {

            current_step = (
                <div>
                    {title_text_block}

                    <div className="p-4">
                        <p>Thank you! Payment is all set.</p>

                        {intro_and_tutorial_block}
                    </div>
                </div>
            )
        }

        if (this.state.user_skipped_payment) {
            current_step = (
                <div>
                    {title_text_block}

                    <div className="p-4">
                        
                        {intro_and_tutorial_block}
                    </div>
                </div>
            )
        }

        if (this.props.creating_account) {
            current_step = <LoadingIndicator />
        }
        if (this.props.failed_creating_account) {
            current_step = <p>Failed to create account. Please try again, or reach out to support@worshipkit.com.</p>
        }

        let monthlyCost = 0.0;

        return (
            <div className="bg-white rounded-lg overflow-hidden md:flex shadow-lg">
                <div id="left_side" className="md:flex-shrink-0 m-0 p-2 w-full md:w-4/6">

                    {current_step}

                </div>
                <div id="right_side" className="relative mt-4 pt-4 md:mt-0 md:ml-0 bg-gray-100 w-full md:w-2/6">
                    {back_button}

                    <div className="p-6">
                        {this.state.selected_apps.map((app) => {

                            let quantity = 1;
                            let cost_per_unit = 0;
                            let quantity_label = " units";
                            if (app.billing_calculation) {
                                quantity = app.billing_calculation.selected_quantity;
                                if (app.billing_calculation.is_per_campus) {
                                    cost_per_unit = app.billing_calculation.cost_per_campus;
                                    quantity_label = " campuses";
                                } else if (app.billing_calculation.is_per_message) {
                                    cost_per_unit = app.billing_calculation.cost_per_message;
                                    quantity_label = " messages";
                                }
                            }
                            
                            
                            let app_cost_total = quantity * cost_per_unit;
                            monthlyCost = monthlyCost + app_cost_total;

                            return (
                                <div key={app.id} className="mb-4">
                                    <p className="font-bold text-lg">{app.name}</p>
                                    <p>{quantity} {quantity_label}</p>
                                    <p className="font-bold">${app_cost_total.toFixed(2)}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className="p-6">
                        <div className="mb-4">
                            <p className="font-bold text-lg">Account Info:</p>
                            <p>{this.state.account_info.church_name}</p>
                            <p>{this.state.account_info.full_name}</p>
                            <p>{this.state.account_info.email}</p>
                            <p><span className="font-bold">Time zone:</span> {this.state.account_info.timezone}</p>
                        </div>
                        <div className="h-10">&nbsp;</div>
                    </div>

                    <div className="float-none clearfix h-4">&nbsp;</div>
                    
                    <div className="absolute bottom-0 left-0 right-0 m-0 border-solid border-t border-gray-400 p-2">
                        <p className="text-3xl font-bold text-gray-900 m-0 ml-4"><span className="text-gray-500">Estimated: </span> ${monthlyCost.toFixed(2)}<span className="text-2xl text-gray-500">/month</span></p>
                    </div>
                </div>
            </div>
        )

    }
}


const mapStateToProps = state => {
    return {
        loading_available_apps: state.billing.loading_available_apps,
        available_apps: state.billing.available_apps,
        creating_account: state.billing.creating_account,
        auth_token: state.billing.auth_token,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAvailableApps: () => dispatch(billingActions.fetchAvailableApps()),
        onCreateAccount: (selected_apps, account_info, payment_method) => dispatch(billingActions.createAccount(selected_apps, account_info, payment_method)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsSignupLayout));
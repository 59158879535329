import React, {Component} from 'react';

class TextField extends Component {

    
    render() {
        
        let handler = (event) => this.props.changeHandler(event, this.props.property_name);

        let is_error_state = false;
        if (this.props.is_error_state) {
            is_error_state = this.props.is_error_state;
        }

        let leftSegment = (
            <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor={this.props.property_name}>
                    {this.props.label}
                </label>
            </div>
        );

        let rightSegment = "md:w-2/3";

        if (!this.props.label || this.props.label.length == 0) {
            leftSegment = (
                <div></div>
            )
            rightSegment = "md:w-full";
        }

        let disabled = false;
        if (this.props.disabled) {
            disabled = this.props.disabled;
        }

        let errorState = "";
        if (is_error_state) {
            errorState = " border-red-500 border-2";
        }

        let input_classes = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" + errorState;

        let input_value = this.props.value;
        if (!input_value) {
            input_value = "";
        }

        return (
            <div className="md:flex md:items-center mb-6">
                {leftSegment}
                <div className={rightSegment}>
                    <input disabled={disabled} className={input_classes} id={this.props.property_name} name={this.props.property_name} type="text" value={input_value} onChange={handler} placeholder={this.props.placeholder} />
                </div>
            </div>
        );
    }
};

export default TextField;
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as billingActions from '../actions/index';

import DetailHeader from '../../global/detail_header/detail_header';
import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
import PlanTableRow from '../../global/table_elements/plan_table_row/plan_table_row';
import { updateObject } from '../../global/utility';
import Button from '../../global/button/button';
import BillingAppGeneralInfo from './billing_app_general_info/billing_app_general_info';

class BillingAppDetails extends Component {

    state = {
        selectedPlanId: null,
        stripeToken: null,
        couponCode: ''
    }

    componentDidMount() {
        this.props.onFetchAppDetails(this.props.match.params.id);
        console.log(this.props.match.params.id);
        this.setState({selectedPlanId: null, stripeToken: null, couponCode: ''});
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.onFetchAppDetails(this.props.match.params.id);
            console.log(this.props.match.params.id);
            this.setState(updateObject(this.state, {selectedPlanId: null}));
        }
    }

    toggleActive() {
        let isActive = false;
        if (this.props.app_data[this.props.match.params.id].subscription_obj) {
            isActive = this.props.app_data[this.props.match.params.id].subscription_obj.is_active;
        }

        if (isActive) {
            this.props.onDeactivateApp(this.props.match.params.id);
        } else {
            this.props.onActivateApp(this.props.match.params.id);
        }
    }

    selectPlan(planId) {
        if (this.state.selectedPlanId == planId) {
            this.setState(updateObject(this.state, {selectedPlanId: null}));
        } else {
            this.setState(updateObject(this.state, {selectedPlanId: planId}));
        }
    }

    updatePlanWithDetails(token, quantity, coupon_code, app_id, auth_token) {
        this.setState(updateObject(this.state, {stripeToken: token}));
        this.props.onSubscribeApp(token, quantity, this.state.selectedPlanId, coupon_code, app_id, auth_token);
        this.setState({selectedPlanId: null, stripeToken: null, couponCode: ""});
    }

    updatePlanToSelected() {

        let app_data = this.props.app_data[this.props.match.params.id];
        let stripe_selected_plan = null;
        
        if (app_data.stripe_available_plans) {
            app_data.stripe_available_plans.map((plan) => {
                if (plan.id == this.state.selectedPlanId) {
                    stripe_selected_plan = plan;
                }
            });
        }

        if (stripe_selected_plan == null) {
            return; // yikes
        }
        
        let customer_has_sources = false;
        let customer_email = '';

        let stripe_publishable = '';

        let quantity = 1;
        let app_name = '';
        let trial_end_date = 0;

        if (app_data.plan_ui_details) {
            quantity = app_data.plan_ui_details.plan_quantity;
            customer_has_sources = app_data.plan_ui_details.customer_has_sources;
            app_name = app_data.app_module.name;
            trial_end_date = app_data.plan_ui_details.trial_end_date;
            customer_email = app_data.plan_ui_details.email;
            stripe_publishable = app_data.plan_ui_details.stripe_publishable;
        }
        let coupon_code = this.state.couponCode;

        let amount = quantity * stripe_selected_plan.amount;
        let interval = stripe_selected_plan.interval;
        let app_id = this.props.match.params.id;
        let auth_token = this.props.auth_token;

        let updateFunc = (token, quantity, coupon_code, app_id, auth_token) => {
            this.updatePlanWithDetails(token, quantity, coupon_code, app_id, auth_token);
        };

        var handler_stripe = StripeCheckout.configure({
            key: stripe_publishable,
            image: 'http://worshipkit.s3.amazonaws.com/wkicon.png',
            token: function(token) {
                // Use the token to create the charge with a server-side script.
                // You can access the token ID with `token.id`
                
                updateFunc(token.id, quantity, coupon_code, app_id, auth_token);
            }
        });

        if (customer_has_sources == false) {
            // Open Checkout with further options
            handler_stripe.open({
                name: 'WorshipKit ' + app_name,
                description: interval + ' subscription',
                panelLabel: "Subscribe",
                zipCode: true,
                allowRememberMe: false,
                amount: amount,
                email: customer_email,
                trial_end: trial_end_date
            });
        } else {
            updateFunc(this.state.stripeToken, quantity, coupon_code, app_id, auth_token);
        }
    }

    cancelActivePlan() {
        console.log("hang on, cancelling plan for " + this.props.match.params.id);
        this.props.onCancelApp(this.props.match.params.id, this.props.auth_token);
    }

    setCouponCode(event) {
        this.setState({couponCode: event.target.value});
        // console.log(event.target.value);
    }

    render () {
        let app_name = '';
        let app_description = '';
        let divClasses = "border-t-4 border-white pt-4";
        let isSubscriptionActive = false;

        let isAppActive = false;
        let isBeta = false;

        let availablePlansDiv = null;
        let currentPlanId = null;

        let updatePlanButton = null;
        let cancelPlanButton = null;
        let planStatusText = null;

        let activationToggleButton = null;

        if (this.props.app_data[this.props.match.params.id]) {
            if (this.props.app_data[this.props.match.params.id].app_module) {
                if (this.props.app_data[this.props.match.params.id].app_module.name) {
                    app_name = this.props.app_data[this.props.match.params.id].app_module.name;
                }
                if (this.props.app_data[this.props.match.params.id].app_module.description) {
                    app_description = this.props.app_data[this.props.match.params.id].app_module.description;
                }
                
                isBeta = this.props.app_data[this.props.match.params.id].app_module.beta;
            }
            if (this.props.app_data[this.props.match.params.id].stripe_subscription_obj) {
                isSubscriptionActive = (this.props.app_data[this.props.match.params.id].stripe_subscription_obj.status == "active" || this.props.app_data[this.props.match.params.id].stripe_subscription_obj.status == "trialing");
                if (isSubscriptionActive) {
                    currentPlanId = this.props.app_data[this.props.match.params.id].stripe_subscription_obj.plan.id;
                    let planLabel = "Cancel Plan";
                    if (this.state.selectedPlanId) {
                        planLabel = "Cancel Current Plan";
                    }
                    cancelPlanButton = (
                        <Button buttonColor="gray" text={planLabel} clickEvent={() => this.cancelActivePlan()} />
                    );

                    if (this.props.app_data[this.props.match.params.id].stripe_subscription_obj.cancel_at_period_end == true) {
                        cancelPlanButton = null;
                        planStatusText = (
                            <p>Plan will cancel at the end of the current billing period.</p>
                        );
                    }
                }
            }

            if (this.props.app_data[this.props.match.params.id].subscription_obj) {
                isAppActive = this.props.app_data[this.props.match.params.id].subscription_obj.is_active;
            }

            if (isAppActive && this.props.app_data[this.props.match.params.id].stripe_available_plans) {
                availablePlansDiv = (
                    <div>
                        {this.props.app_data[this.props.match.params.id].stripe_available_plans.map((plan) => (
                            <PlanTableRow key={plan.id} selectedPlanId={this.state.selectedPlanId} activePlanId={currentPlanId} planId={plan.id} active={plan.active} nickname={plan.nickname} tiers={plan.tiers} amount={plan.amount / 100} term={plan.interval} clickEvent={() => this.selectPlan(plan.id)} />
                        ))}
                    </div>
                );
            }

            

            let switchClass = "inline-block bg-gray-500 m-3 p-4 font-bold cursor-pointer";
            if (isAppActive) {
                switchClass = "inline-block bg-blue-500 text-white m-3 p-4 font-bold cursor-pointer";
            }

            if (isBeta) {
                divClasses = "border-t-4 border-purple-500 pt-4";
            }

            activationToggleButton = (
                <Button buttonColor="gray" text={isAppActive ? 'Deactivate ' + app_name : 'Activate ' + app_name } clickEvent={() => this.toggleActive()} />
            );
            if (isAppActive && isSubscriptionActive) {
                activationToggleButton = null;
            }
        }
        
        if (this.state.selectedPlanId) {
            updatePlanButton = (
                <Button text="Change Plan" clickEvent={() => this.updatePlanToSelected()} />
            );
        }
        

        let contents = (
            <LoadingIndicator />
        );

        if (!this.props.loading_app_details) {

            let outgoing_numbers = [];
            let used_credits = 0;
            let sent_sms_messages = 0;
            let sent_mms_messages = 0;

            let periodEnd = 0;

            let example_end = 0;

            let remaining_days = 0;
            let quantity = 0;
            let monthly_cost = 0;
            let app_id = this.props.match.params.id;
            let app_data = this.props.app_data[app_id];
            let last_month = {};

            if (app_data) {
                if (app_data.plan_ui_details) {
                    outgoing_numbers = app_data.plan_ui_details.outgoing_numbers;
                    used_credits = app_data.plan_ui_details.used_credits;
                    sent_sms_messages = app_data.plan_ui_details.sent_sms_messages;
                    sent_mms_messages = app_data.plan_ui_details.sent_mms_messages;
                    quantity = app_data.plan_ui_details.plan_quantity;
                    last_month = app_data.plan_ui_details.last_month;
                }

                if (app_data.stripe_subscription_obj) {
                    
                    monthly_cost = app_data.stripe_subscription_obj.plan.amount;
                    
                    example_end = app_data.stripe_subscription_obj.current_period_end;

                    let example_now = Math.floor(new Date().getTime() / 1000);
                    let difference = example_end - example_now;
                    let one_day = 86400;
                    let days_difference = difference / one_day;
                    remaining_days = Math.floor(days_difference);
                }
            }

            let billingString = null;

            let remainingLabel = 'days remain';
            if (remaining_days == 1) {
                remainingLabel = 'day remains';
            }
            if (remaining_days > 0) {
                billingString = (
                    <p>{remaining_days} {remainingLabel} in your current billing period.</p>
                );
            }
            
            let app_descriptive_details = (
                <div>
                    {billingString}
                    <Switch>
                        
                        <Route path="*" render={() => (
                            <BillingAppGeneralInfo plan_quantity={quantity} plan_monthly_fee={monthly_cost} {...this.props} />
                        )} />
                    </Switch>
                </div>
            );

            let couponCodeField = (
                <div>
                </div>
            );

            if (this.state.selectedPlanId) {
                couponCodeField = (
                    <div className="clearfix">
                        <label>Enter coupon code</label><br />
                        <input type="text" className="shadow appearance-none border rounded w-1/4 float-left py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(event) => this.setCouponCode(event)} value={ this.state.couponCode } /> 
                        <br />
                    </div>
                );
            }

            contents = (
                <div className={divClasses}>
                    
                    <DetailHeader title={app_name} subtitle={app_description} />

                    {app_descriptive_details}

                    {availablePlansDiv}

                    {couponCodeField}

                    {cancelPlanButton}
                    {updatePlanButton}

                    {planStatusText}
                    
                    {activationToggleButton}
                </div>
            );
        }

        return contents;
    }
}

const mapStateToProps = state => {
    return {
        app_data: state.billing.apps,
        loading_app_details: state.billing.loading_app_details,
        auth_token: state.billing.auth_token
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAppDetails: (short_name) => dispatch(billingActions.fetchAppDetails(short_name)),
        onDeactivateApp: (short_name) => dispatch(billingActions.deactivateApp(short_name)),
        onActivateApp: (short_name) => dispatch(billingActions.activateApp(short_name)),
        onCancelApp: (short_name, auth_token) => dispatch(billingActions.cancelApp(short_name, auth_token)),
        onSubscribeApp: (stripeToken, quantity, plan_id, coupon_code, short_name, auth_token) => dispatch(billingActions.subscribeApp(stripeToken, quantity, plan_id, coupon_code, short_name, auth_token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAppDetails);
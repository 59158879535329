import React, {Component} from 'react';
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import * as usersActions from '../actions/index';

import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
import UsersPermissionRow from './users_permission_row/users_permission_row';
import DetailHeader from '../../global/detail_header/detail_header';
import Button from '../../global/button/button';
import CustomPermissionsGroup from './custom_permissions_group';

class UsersPermissions extends Component {

    state = {
        
    }

    componentDidMount() {
        this.props.onFetchPermissionsLevels(this.props.auth_token);
        this.props.onFetchPermissionsLevelPropertyDescriptions(this.props.auth_token);
    }

    addPermissionLevel() {
        this.props.history.push("/users/permissions_levels");
    }

    editPermissionsLevel(level) {
        this.props.history.push("/users/" + level.id + "/level");
    }

    reorderPermissionsLevels(permissions_levels) {
        this.props.onReorderPermissionsLevels(this.props.auth_token, permissions_levels);
    }

    render () {

        let contents = (
            <LoadingIndicator />
        );
        
        if (!this.props.loading_permissions_levels) {

            let shared_levels = [];//this.props.shared_permissions_levels;
            if (this.props.shared_permissions_levels) {
                shared_levels = this.props.shared_permissions_levels;
                shared_levels.sort(function(a, b) {
                    let sortA = a.sequence_index;
                    let sortB = b.sequence_index;
                    if (sortA < sortB) {
                        return -1;
                      }
                      if (sortA > sortB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                });
            }

            let custom_levels = [];
            if (this.props.custom_permissions_levels) {
                custom_levels = this.props.custom_permissions_levels;
                custom_levels.sort(function(a, b) {
                    let sortA = a.sequence_index;
                    let sortB = b.sequence_index;
                    if (sortA < sortB) {
                        return -1;
                      }
                      if (sortA > sortB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                });
            }
                        
            let generalPermissionsMarkup = (
                <div>
                    <DetailHeader title="General Permissions levels" subtitle="A starter pack of permissions levels, included with WorshipKit" />
                    {shared_levels.map((level) => (
                        <UsersPermissionRow key={level.id} level={level} propertyDetails={this.props.permissions_level_property_descriptions} />
                    ))}
                </div>
            );

            if (!shared_levels || shared_levels.count == 0) {
                generalPermissionsMarkup = (
                    <div></div>
                );
            }

            contents = (
                <div>
                    {generalPermissionsMarkup}

                    <div className="float-right">
                        <Button text="Add New" clickEvent={() => {this.addPermissionLevel()}} />
                    </div>

                    <DetailHeader title="Custom Permissions levels" subtitle="Your organization's permissions levels. Drag to reorder." />
                    <DndProvider backend={Backend}>
                        <CustomPermissionsGroup custom_levels={custom_levels} editPermissionsLevel={(level) => {this.editPermissionsLevel(level)}} permissions_level_property_descriptions={this.props.permissions_level_property_descriptions} reorderPermissionsLevels={(permissions_levels) => {this.reorderPermissionsLevels(permissions_levels)}} />
                    </DndProvider>
                    
                </div>
            );
        }
        
        return contents;
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.users.auth_token,
        shared_permissions_levels: state.users.shared_permissions_levels,
        custom_permissions_levels: state.users.custom_permissions_levels,
        loading_permissions_levels: state.users.loading_permissions_levels,
        loading_property_descriptions: state.users.loading_property_descriptions,
        permissions_level_property_descriptions: state.users.permissions_level_property_descriptions
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPermissionsLevels: (auth_token) => dispatch(usersActions.fetchPermissionsLevels(auth_token)),
        onFetchPermissionsLevelPropertyDescriptions: (auth_token) => dispatch(usersActions.fetchPermissionsLevelPropertyDescriptions(auth_token)),
        onReorderPermissionsLevels: (auth_token, permissions_levels) => dispatch(usersActions.reorderPermissionsLevels(auth_token, permissions_levels))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPermissions);
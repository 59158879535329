import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchNavigationSuccess = (details) => {
    return {
        type: actionTypes.FETCH_NAVIGATION_SUCCESS,
        details: details
    }
};

export const fetchNavigationFailure = (error) => {
    return {
        type: actionTypes.FETCH_NAVIGATION_FAILURE,
        error: error
    };
};

export const fetchNavigationStart = () => {
    return {
        type: actionTypes.FETCH_NAVIGATION_START
    }
};

export const fetchNavigation = () => {
    return dispatch => {
        dispatch(fetchNavigationStart());
        
        axios.get('/dash/app_frame_structure.json')
        .then(res => {
            console.log("navigation structure: " + res.data);
            dispatch(fetchNavigationSuccess(res.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(fetchNavigationFailure(err));
            alert(err.message);
        });
    }
}

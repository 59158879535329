import React, {Component} from 'react';


import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import AccountsSignupLayout from './accounts_signup_layout';
import billingReducer from '../billing/billing_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_VfDjtpsFc31CcJiOmgMMv2Ur');
// const stripePromise = loadStripe(this.props.stripe_key);

const rootReducer = combineReducers({
    billing: billingReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class AccountsSignupApp extends Component {

    render () {
        return (
            <Elements stripe={loadStripe(this.props.stripe_key)}>
                <Provider store={store}>
                    <BrowserRouter>
                        <AccountsSignupLayout stripe_key={this.props.stripe_key} />
                    </BrowserRouter>
                </Provider>
            </Elements>
        )
    }
}

export default AccountsSignupApp;
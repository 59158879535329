import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import BillingLayout from './billing_layout/billing_layout';
import billingReducer from './billing_reducer';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    billing: billingReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class BillingApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <BillingLayout />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default BillingApp;
import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    auth_token: null,
    users: [ ],
}


const updateToken = (state, action) => {
    return updateObject(state, { auth_token: action.details.auth_token });
}



const usersStart = (state, action) => {
    return updateObject(state, { });
};

const usersSuccess = (state, action) => {
    return updateObject(state, { users: action.users, loading_users: false });
};

const usersFailure = (state, action) => {
    return updateObject(state, { loading_users: false });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TOKEN: return updateToken(state, action);
        case actionTypes.FETCH_USERS_START: return usersStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS: return usersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAILURE: return usersFailure(state, action);
        default: return state;
    }
};

export default reducer;
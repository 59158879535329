import React, {useState, useCallback} from 'react';
import Button from '../../global/button/button';
import UsersPermissionRow from './users_permission_row/users_permission_row';
// import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
// import { updateObject } from '../../global/utility';
import update from 'immutability-helper'

import ItemTypes from './item_types';

const CustomPermissionsGroup = ({custom_levels, editPermissionsLevel, permissions_level_property_descriptions, reorderPermissionsLevels}) => {

    const [permissions, setPermissions] = useState(custom_levels);
    const [needsDraw, setNeedsDraw] = useState({needsDraw: true});

    const movePermission = useCallback(
        (dragIndex, hoverIndex) => {
            console.log("update index " + dragIndex + " to index " + hoverIndex);
            const dragPermission = permissions[dragIndex]
            setPermissions(
                update(permissions, {
                    $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragPermission],
                    ],
                }),
            )
        },
        [permissions],
    );

    const dropPermission = useCallback(
        () => {
            
            let loop_index = 0;
            permissions.forEach((item) => {
                console.log("item " + item.name + " is prev: " + item.sequence_index);
                item.sequence_index = loop_index;
                console.log("item " + item.name + " is now: " + item.sequence_index);
                loop_index++;
            })

            setNeedsDraw({needsDraw: true})

            reorderPermissionsLevels(permissions);
        }
    )

    return (
        <div>
            {permissions.map((level) => (
                <UsersPermissionRow key={level.id} editEvent={() => {editPermissionsLevel(level)}} level={level} propertyDetails={permissions_level_property_descriptions} movePermission={movePermission} dropPermission={dropPermission} />
            ))}
        </div>
    )
}


export default CustomPermissionsGroup;
import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import PageTabHeaderView from './page_tab_header_view';

import usersReducer from '../user/user_reducer';
import organizationReducer from '../organization/organization_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    user: usersReducer,
    organization: organizationReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class PageTabHeaderApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <PageTabHeaderView request_uri={this.props.request_uri} />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default PageTabHeaderApp;
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as usersActions from '../../actions/index';

import { updateObject } from '../../../global/utility';
import Button from '../../../global/button/button';
import LoadingIndicator from '../../../global/loading_indicator/loading_indicator';
import UsersAppGeneralInfo from '../user_general_info/user_general_info';
import UserGeneralInfo from '../user_general_info/user_general_info';
import SelectBox from '../../../global/select_box/select_box';

class UserDetailPermission extends Component {

    state = {
        
    }

    componentDidMount() {
        this.props.onFetchPermissionsLevels(this.props.auth_token);
        this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
        this.props.onFetchCurrentUserPermissions(this.props.auth_token);
        this.props.onFetchSelectedUserPermissions(this.props.match.params.id, this.props.auth_token);
        console.log(this.props.match.params.id);
        this.setState({});
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.onFetchPermissionsLevels(this.props.auth_token);
            this.props.onFetchUserDetails(this.props.match.params.id, this.props.auth_token);
            this.props.onFetchCurrentUserPermissions(this.props.auth_token);
            this.props.onFetchSelectedUserPermissions(this.props.match.params.id, this.props.auth_token);
            console.log(this.props.match.params.id);
            this.setState(updateObject(this.state, {}));
        }
    }

    savePermissions() {
        this.props.onSaveUserPermissions(this.props.selected_user_permissions, this.props.selected_user_permissions.organization_permissions.user, this.props.auth_token);
        this.props.history.push("/users/" + this.props.match.params.id);
    }

    cancelPermissions() {
        this.props.history.push("/users/" + this.props.match.params.id);
    }
    
    changeHandler(event, object, property_name) {
        console.log("value changed: " + event.target.value);
        object.permissions_level_id = event.target.value;
        this.setState({})
    }

    render () {
        
        if (!this.props.shared_permissions_levels || !this.props.custom_permissions_levels) {
            return (
                <LoadingIndicator />
            );
        }


        let levels = [];
        
        if (this.props.shared_permissions_levels && this.props.custom_permissions_levels) {
            this.props.shared_permissions_levels.forEach(function(item, index) {
                levels.push({"value":item.id, "label":item.name});
                console.log("item: " + item);
            });
            console.log("shared: " + this.props.shared_permissions_levels);
            this.props.custom_permissions_levels.forEach(function(item, index) {
                levels.push({"value":item.id, "label":item.name});
                console.log("item: " + item);
            });
            console.log("custom: " + this.props.custom_permissions_levels);
    
            console.log("levels: " + levels);
        }

        let divClasses = "border-t-4 border-white pt-4";
        
        let contents = (
            <LoadingIndicator />
        );

        let editable = true;
        

        if (this.props.current_user && !this.props.loading_current_user_permissions) {

            if (this.props.current_user_permissions.organization_permissions.permissions_level) {
                editable = this.props.current_user_permissions.organization_permissions.permissions_level.edit_permissions;
            }

            // if current user is this user, or current user doesn't have editing rights, disable the ability to modify.
            // in the future, we might need to fork this behavior out with variations, but this is adequate for now.
            if (this.props.current_user.id == this.props.match.params.id) {
                editable = false;
            }

            if (!this.props.loading_selected_user && !this.props.loading_selected_user_permissions && this.props.selected_user_permissions) {
                
                let orgListsTitle = (
                    <p className="w-full p-2 bg-gray-100 border-b-1 border-solid-1 border-gray-200 mb-2">Lists for {this.props.selected_user_permissions.organization_permissions.organization.name}</p>
                );
                
                let editableProperties = (
                    <div>
                        <p>organization</p>
                        <SelectBox label={this.props.selected_user_permissions.organization_permissions.organization.name} value={this.props.selected_user_permissions.organization_permissions.permissions_level_id} object={this.props.selected_user_permissions.organization_permissions} options={levels} property_name="org_permissions" changeHandler={(event) => this.changeHandler(event, this.props.selected_user_permissions.organization_permissions, "org_permissions")} />
                        
                        


                        <p className="w-full p-2 bg-gray-100 border-b-1 border-solid-1 border-gray-200 mb-2">Ministries</p>
                        
                        {this.props.selected_user_permissions.ministry_permissions.map((ministry_payload, index) => {
                            
                            let role = ministry_payload.role;
                            if (!role) {
                                role = {"user_id":this.props.selected_user.id, "ministry_id":ministry_payload.ministry.id, "permissions_level_id":this.props.selected_user_permissions.organization_permissions.permissions_level_id, "user":this.props.selected_user, "ministry":ministry_payload.ministry, "permissions_level":this.props.selected_user_permissions.organization_permissions.permissions_level, "modified":false};
                                ministry_payload.role = role;
                            }


                            return (
                                <div key={ministry_payload.ministry.id}>
                                    
                                    <SelectBox key={"ministry_" + ministry_payload.ministry_id + "_role"} label={ministry_payload.ministry.name} value={role.permissions_level_id} object={role} options={levels} property_name={"ministry_" + ministry_payload.ministry_id + "_role"} changeHandler={(event) => this.changeHandler(event, role, "ministry_" + ministry_payload.ministry_id + "_role")} />

                                </div>
                            );
                        })}

                        
                    </div>
                );

                let actionButtons = (
                    <div>
                        <Button text="Save" clickEvent={() => this.savePermissions()} />
                        <Button text="Cancel" buttonColor="gray" clickEvent={() => this.cancelPermissions()} />
                    </div>
                );

                if (!editable) {
                    editableProperties = (
                        <div></div>
                    );
                    actionButtons = (
                        <div></div>
                    );
                }

                contents = (
                    
                    <div className={divClasses}>
                        <UserGeneralInfo user={this.props.selected_user} />
                        {editableProperties}
                        {actionButtons}
                    </div>
                );
            }
        }
        
        
        return contents;
    }
}

const mapStateToProps = state => {
    return {
        loading_permissions_levels: state.users.loading_permissions_levels,
        shared_permissions_levels: state.users.shared_permissions_levels,
        custom_permissions_levels: state.users.custom_permissions_levels,
        loading_selected_user: state.users.loading_selected_user,
        selected_user: state.users.selected_user,
        loading_current_user: state.users.loading_current_user,
        current_user: state.users.current_user,
        auth_token: state.users.auth_token,
        loading_selected_user_permissions: state.users.loading_selected_user_permissions,
        selected_user_permissions: state.users.selected_user_permissions,
        loading_current_user_permissions: state.users.loading_current_user_permissions,
        current_user_permissions: state.users.current_user_permissions,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchSelectedUserPermissions: (user_id, auth_token) => dispatch(usersActions.fetchSelectedUserPermissions(user_id, auth_token)),
        onFetchCurrentUserPermissions: (auth_token) => dispatch(usersActions.fetchCurrentUserPermissions(auth_token)),
        onSaveUserPermissions: (permissions, user, auth_token) => dispatch(usersActions.saveUserPermissions(permissions, user, auth_token)),
        onFetchCurrentUser: (auth_token) => dispatch(usersActions.fetchCurrentUser(auth_token)),
        onFetchUserDetails: (user_id, auth_token) => dispatch(usersActions.fetchUserDetails(user_id, auth_token)),
        onFetchPermissionsLevels: (auth_token) => dispatch(usersActions.fetchPermissionsLevels(auth_token)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPermission);
import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { updateObject } from '../utility'

import Pusher from 'pusher-js';

import * as userActions from '../user/actions/index';
import * as organizationActions from '../organization/actions/index';

class PageTabHeaderView extends Component {

    state ={
        clicked_element: null,
    }

    constructor(props) {
        super(props);
        this.state = { clicked_element: null };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    pusher = null;
    channel = null;

    initPusher() {
        // console.log("org: " + JSON.stringify(this.props.organization));
        if (this.props.organization && this.props.organization.pusher_keys.pusher_key) {
            // console.log("organization pusher: " + this.props.organization.pusher_keys)
            this.pusher = new Pusher(this.props.organization.pusher_keys.pusher_key, {
                cluster: "mt1",
                encrypted: true
            });
            console.log("pusher: " + this.pusher)
        }
    }

    unsubOldChannel() {
        if (this.channel) {
            this.pusher.unsubscribe(this.channel.name)
            this.channel = null;
        }
    }

    initChannel() {
        if (this.props.organization && this.props.organization.id && this.pusher && !this.channel) {
            this.channel = this.pusher.subscribe("org_" + this.props.organization_id);
            console.log("binding to channels in org_" + this.props.organization_id)
        }
    }

    componentDidMount() {
        this.props.onUpdateAuthToken((token) => {
            this.props.onUpdateOrganization(this.props.auth_token);
            this.props.onFetchAppTabs(this.props.auth_token);
        });
        this.initPusher()
        this.initChannel()
    }

    componentDidUpdate(prevProps) {
        // if (this.props !== prevProps) {
            this.initPusher()
            this.initChannel()
        // }
    }

    handleMouseDown(element_id) {
        this.setState(updateObject(this.state, {
            clicked_element: element_id
        }));
    }

    handleMouseUp(element_id) {
        if (this.state.clicked_element == element_id) {
            this.setState(updateObject(this.state, {
                clicked_element: null,
            }))
        }
    }

    render() {

        let regularIconColor = "text-gray-500 hover:text-gray-900 ";
        let regularStyle = "text-gray-500 bg-white w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-small text-xs md:text-base leading-5 hover:text-gray-900 hover:bg-gray-300 focus:outline-none focus:text-gray-900 focus:border-gray-300 rounded";
        
        let activeIconColor = "text-white hover:text-white";
        let activeStyle = "text-white bg-blue-500 w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-small text-xs md:text-base leading-5 hover:text-white focus:text-gray-500 rounded";

        return (
            <div className="pb-2">
                
                <div className="block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex">
                            {this.props.app_bar_links.map((link) => (
                                <Route key={link.element_id} path={link.url} children={({ match }) => {
                                    let styles = regularStyle;
                                    let iconColor = regularIconColor;
                                    
                                    if (match) {
                                        styles = activeStyle;
                                        iconColor = activeIconColor;
                                    }

                                    let iconClasses = "inline-block align-middle m-0 p-0 w-6 h-6 md:h-5 md:w-5";
                                    let icon = (
                                        <svg className={iconClasses} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d={link.svg}
                                            />
                                        </svg>
                                    );

                                    let badge = (
                                        <div></div>
                                    )

                                    if (link.badge_count) {
                                        badge = (
                                            <span className="ml-5 inline-block py-0.5 px-3 text-xs text-white leading-4 font-medium rounded-full bg-red-500 transition ease-in-out duration-150 group-hover:bg-red-500 group-focus:bg-red-500">
                                            {link.badge_count}
                                            </span>
                                        )
                                    }

                                    return (
                                        <a key={link.element_id} href={link.url} className={styles} onMouseDown={() => this.handleMouseDown(link.element_id)} onMouseUp={() => this.handleMouseUp(link.element_id)}>{icon} <span className="hidden md:inline-block ml-2 align-middle">{link.name}</span>{badge}</a>
                                    );
                                }} />  
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        organization_id: state.organization.organization_id,
        auth_token: state.user.auth_token,
        organization: state.organization.organization,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateOrganization: (auth_token) => dispatch(organizationActions.updateOrganization(auth_token)),
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTabHeaderView));
import React from "react";

const Checkbox = ({ label, isSelected, onCheckboxChange }) => {

    return (
        <div className="form-check float-left my-4 mr-4 p-2">
          <label>
            <input
              type="checkbox"
              name={label}
              checked={isSelected}
              onChange={onCheckboxChange}
              className="form-check-input"
            />  {label}
          </label>
        </div>
    )
};

export default Checkbox;
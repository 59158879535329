import React, {Component} from 'react';

import classes from './section_header.css';

class SectionHeader extends Component {

    render () {
        return (
            <div className="cursor-default font-light text-left text-gray-500 py-2 px-6 mt-5">
                <p className="text-sm">{this.props.text}</p>
            </div>
        );
    }
}

export default SectionHeader;
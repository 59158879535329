import React, {Component, Suspense} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import * as slidesActions from '../actions/index';
import * as userActions from '../../global/user/actions/index';

const SplitView = React.lazy(() => import('../../global/split_view/split_view'));
const SectionHeader = React.lazy(() => import('../../global/table_elements/section_header/section_header'));
const TableRow = React.lazy(() => import('../../global/table_elements/table_row/table_row'));

const SlidesIndex = React.lazy(() => import('../slides_index/slides_index'));
import LoadingIndicator from '../../global/loading_indicator/loading_indicator';
// const PagerSendView = React.lazy(() => import('../pager_send_view/pager_send_view'));

class SlidesLayout extends Component {

    constructor(props) {
        super(props);
        this.authTokenCallback = this.authTokenCallback.bind(this);
    }

    componentDidMount() {
        // this.props.onUpdateAuthToken(this.authTokenCallback);
    }

    authTokenCallback(auth_token) {
        // this.props.onFetchMinistries(auth_token, this.ministryLoadCallback);
    }

    render () {
        return (
            <Suspense fallback={<LoadingIndicator />}>
                <SlidesIndex />
            </Suspense>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.user.auth_token,
        publishing_slides: state.slides.publishing_slides,
        publish_result: state.slides.publish_result
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback)),
        onPublishingSlides: (auth_token, slideshow_hash) => dispatch(slidesActions.publishSlideshow(auth_token, slideshow_hash))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidesLayout));
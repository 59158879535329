import React, {Component} from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import devToolsEnhancer from 'remote-redux-devtools';

import HomeIndexLayout from './home_index_layout';
import billingReducer from '../billing/billing_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    billing: billingReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

class HomeIndexApp extends Component {

    render () {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <HomeIndexLayout links={this.props.links} />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default HomeIndexApp;
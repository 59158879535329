import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import * as userActions from '../../global/user/actions/index';

import LoadingIndicator from '../../global/loading_indicator/loading_indicator';

class PagerIndex extends Component {


    componentDidMount() {
        
    }

    render () {
        
        let content = (
            <div className="text-center">
                <p className="text-gray-500 text-xl align-middle">Select a ministry from the list to page a parent</p>
            </div>
        );

        if (this.props.loading_ministries) {
            content = (
                <LoadingIndicator />
            )
        }

        return content;
    }
}

const mapStateToProps = state => {
    return {
        loading_ministries: state.pager.loading_ministries,
        ministries: state.pager.ministries,
        selected_ministry: state.pager.selected_ministry,
        auth_token: state.user.auth_token,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMinistries: (auth_token) => dispatch(pagerActions.fetchMinistries(auth_token)),
        onUpdateAuthToken: (callback) => dispatch(userActions.updateAuthToken(callback))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagerIndex));
import React, {Component} from 'react';
import {matchPath} from 'react-router';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

class SwitchControl extends Component {

    render() {

        let isOn = false;
        if (this.props.selected) {
            isOn = this.props.selected;
        }

        let properties = "w-full sm:w-1/2 mb-2 pb-1 hover:bg-gray-200 focus:bg-gray-400 active:bg-gray-400 cursor-pointer";
        

        let switchElement = (
            <div className="m-2 w-12 h-6 bg-red-500 rounded float-left border-gray-700 border-1 border-solid">
                <div className="w-1/2 h-full bg-white rounded shadow-md border-gray-700 border-1 border-solid">&nbsp;</div>
            </div>
        );

        if (isOn) {
            switchElement = (
                <div className="m-2 w-12 h-6 bg-green-500 border-gray-700 border-1 border-solid rounded float-left">
                    <div className="w-1/2 h-full bg-white border-gray-700 border-1 border-solid rounded shadow-md float-right">&nbsp;</div>
                </div>
            )
        }

        let description = "";
        if (this.props.description) {
            description = <p className="text-gray-700 text-base">{this.props.description}</p>
        }

        return (
            <div className={properties} onClick={this.props.clickEvent}>
                
                <div className="px-6 py-2">
                    {switchElement}
                    <div className="font-bold text-md mb-2">{this.props.title}</div>
                    <div className="clearfix"></div>
                    {description}
                </div>
            </div>
        );
    }
}

export default SwitchControl;
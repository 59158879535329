import React, {Component} from 'react';

import classes from './centered_panel.css';

class CenteredPanel extends Component {

    render () {
        return (
            <div className="container mx-auto flex">
                <div className="mx-auto">
                    <div className="bg-white text-gray-800 rounded border-b-2 shadow-md py-2 px-6 inline-flex">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default CenteredPanel;
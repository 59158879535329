import React, {Component, Suspense} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import * as billingActions from '../actions/index';

import billingIcon from './billing_icon_transparent.png'
import LoadingIndicator from '../../global/loading_indicator/loading_indicator';

const SectionHeader = React.lazy(() => import('../../global/table_elements/section_header/section_header'));
const TableRow = React.lazy(() => import('../../global/table_elements/table_row/table_row'));

const BillingDetails = React.lazy(() => import('../billing_details/billing_details'));
const BillingAppDetails = React.lazy(() => import('../billing_app_details/billing_app_details'));
const SplitView = React.lazy(() => import('../../global/split_view/split_view'));

class BillingLayout extends Component {

    constructor(props) {
        super(props);
        this.splitView = React.createRef();
    }

    componentDidMount() {
        this.props.onUpdateAuthToken();
        this.props.onFetchApps();
        this.props.onFetchInactiveApps();
    }

    goToPage = (pageLink) => {
        this.props.history.push(pageLink);
        this.splitView.current.collapsePopover();
    }

    render () {

        let active_apps_section = (
            <div>
                <Suspense fallback={<LoadingIndicator />}>
                    <SectionHeader text="Active Apps" />
                </Suspense>
                {this.props.active_apps.map((app) => (
                    <Route key={app.id} path={app.link} children={({match}) => (
                        <Suspense fallback={<LoadingIndicator />}>
                            <TableRow key={app.id} selected={match} clickEvent={() => this.goToPage(app.link)} beta={app.beta} text={app.name} link={app.link} icon={app.icon} />
                        </Suspense>
                    )} />
                ))}
            </div>
        );
        if (this.props.loading_active_apps) {
            active_apps_section = (
                <div>

                </div>
            );
        }

        let inactive_apps_section = (
            <div>
                <Suspense fallback={<LoadingIndicator />}>
                    <SectionHeader text="Other Apps" />
                </Suspense>
                {this.props.inactive_apps.map((app) => (
                    <Route key={app.id} path={app.link} children={({match}) => (
                        <TableRow key={app.id} selected={match} clickEvent={() => this.goToPage(app.link)} beta={app.beta} text={app.name} link={app.link} icon={app.icon} />
                    )} />
                ))}
            </div>
        );

        if (this.props.loading_inactive_apps) {
            inactive_apps_section = (
                <div>

                </div>
            );
        }

        let left = (
            <div>
                <Suspense fallback={<LoadingIndicator />}>
                    <Route exact path="/billing" children={({match}) => (
                        <TableRow selected={match} clickEvent={() => this.goToPage('/billing')} link="/billing" text="Billing Details" icon={billingIcon} />
                    )} />
                </Suspense>
                
                {active_apps_section}
                
                {inactive_apps_section}
            </div>
        );

        let right = (
            <Suspense fallback={<LoadingIndicator />}>
                <Switch>
                    <Route path="/billing" exact component={BillingDetails} />
                    <Route path="/billing/apps/:id" component={BillingAppDetails} />
                </Switch>
            </Suspense>
        );

        return (
            <Suspense fallback={<LoadingIndicator />}>
                <SplitView ref={this.splitView} left={left} right={right} />
            </Suspense>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading_active_apps: state.billing.loading_active_apps,
        loading_inactive_apps: state.billing.loading_inactive_apps,
        active_apps: state.billing.active_apps,
        inactive_apps: state.billing.inactive_apps,
        selected_app: state.billing.selected_app
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchApps: () => dispatch(billingActions.fetchApps()),
        onFetchInactiveApps: () => dispatch(billingActions.fetchInactiveApps()),
        onUpdateAuthToken: () => dispatch(billingActions.updateAuthToken())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingLayout));